
.or-forms__form {
    display: flex;
    flex-direction: column;
    .or-forms__input-phone {
        &.has-value {
            label {
                &:after {
                    background-image: url(../img/check.svg);
                    opacity: 1;
                    transition: opacity .1s ease-out;
                } 
            }
        }
    }
    .or-forms__form-group {
        height: 80px;
        margin: 12px auto;
        position: relative;
        order: 2;
        width: 100%;
        &.focused, &.has-value {
            label {
                align-items: flex-start;
                padding: 8px 0px 0px 18.5px;
                font-size: 14px;
                transform: translateY(0px);
                color: $grey-l1;
            }
        }
        &.has-value {
            input[valid] + label, textarea[valid] + label {
                &:after {
                    background-image: url(../img/check.svg);
                    opacity: 1;
                    transition: opacity .1s ease-out;
                } 
            }
        }
        input {
            border: solid 2px $grey-l1;
            height: 100%;
            width: 100%;
            padding: 8px 16px 0px;
            &:focus {
                outline: none;
            }
            &[invalid] {
                border-color: red;
            }
            &[invalid] + label {
                &:after {
                    background-image: url(../img/invalid-email.svg);
                    opacity: 1;
                    transition: all .1s ease-out;
                    width: 20px;
                }
            }
        }
        label {
            position: absolute;
            width: 100%;
            height: 100%;
            margin: 0px;
            padding-left: 20px;
            display: flex;
            top: 0px;
            align-items: center;
            color: $font-color-primary;
            &:after {
                content: "";
                position: absolute;
                height: 80px;
                background-position: center;
                background-repeat: no-repeat;
                width: 24px;
                top: 0px;
                right: 40px;
                opacity: 0;
                transition: opacity .2s cubic-bezier(.86,.16,.88,.84);
                @include media-breakpoint-down(md) {
                    right: 20px;
                }
            }
            
        }
        &.or-forms__input-comment {
            height: auto;
            &.focused, &.has-value {
                label {
                    padding-top: 8px;
                    color: $grey-l1;
                }
            }
            textarea {
                min-height: 80px;
                width: 100%;
                max-height: 300px;
                height: 300px;
                border: solid 2px $grey-l1;
                padding: 28px 0px 0px 16px;
                &::placeholder {
                    opacity: 0;
                }
                &:focus {
                    outline: none;
                }
                &[invalid] {
                    border-color: red;
                }
                &[invalid] + label {
                    &:after {
                        background-image: url(../img/invalid-email.svg);
                        opacity: 1;
                        transition: all .1s ease-out;
                        width: 20px;
                    }
                }
            }
            label {
                align-items: flex-start;
                padding-top: 20px;
            }
        }
        .or-form-input__error {
            display: none;
        }
    }

    .or-forms__link-wrapper {
        order: 1;
        a {
            margin: 40px 0px;
            display: inline-block;
            text-decoration: none;
            color: #595959;
            border-bottom: solid 3px rgba(60, 157, 211, 0.5);
        }
    }

    .or-forms__submit-wrapper {
        order: 2;
        button {
            width: 94px;
            height: 48px;
            background-color: $blue;
            border-radius: 4px;
            font-size: 18px;
            color: white;
            display: block;
            margin: 0 auto;
            border: none;
            &:disabled {
                background-color: rgba($grey-l1, .3); 
            }
        }
    }
}