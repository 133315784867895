.secondary-header-block{
    // margin-top: 200px;
    margin-bottom: 250px;

    @include media-breakpoint-down(md){
        margin-top: 20px;
        margin-bottom: 20px;
    }
    // @include media-breakpoint-down(lg){
    //     max-width: 1425px;
    // }
    >.row{
        position: relative;
    }
    .text-container{
        background-color: $background-color;
        border: 1px solid $border-color;
        padding: 60px 30px 50px 50px;
        min-height: 350px;
        position: relative;
        box-shadow: 0 10px 40px 0 rgba(0,0,0,0.15);
        @include media-breakpoint-down(sm){
            padding: 50px 45px 50px 30px;
        }
        h3{
            color: $neongreen;
            line-height: 28px !important;
        }
        p{
            line-height: 23px !important;
            b{
                font-weight: 700;
            }
        }
        .link{
            height: 35px;
            width: 208px;
            border-radius: 4px;
            background-color: $grey-d1;
            position: absolute;
            bottom: 49px;
            a{
                text-decoration: none;
                color: $white;
                font-size: 16px !important;
                margin-bottom: 0;
            }
        }
    }
    .img-col{
        position: absolute;
        right: 0;
        z-index: -1;
        @include media-breakpoint-down(md){
            position: relative;
            min-width: 110%;
            margin-left: -5%;
            margin-top: -25px;
        }
        @include media-breakpoint-down(xs){
            min-width: unset;
            margin-left: 0;
            padding: 0;
        }
        img{
            max-width: 100%;
            height: auto;
            width: 100%;
            object-fit: cover;
        }
    }
}