@import "helpers";
@import "colors"; 
@import "fonts";
@import "custom";
@import "node_modules/bootstrap/scss/bootstrap";
@import "base";
@import "select2";
@import "search";
@import "errors";

/* Components */
@import "header";
@import "footer";
@import "cookie";

/* Blocks */
@import "blocks/paragraph_block";
@import "blocks/subsidiary_block";
@import "blocks/financial_figures_block";
@import "blocks/news_overview_block";
@import "blocks/section_heading_block";
@import "blocks/area_slider";
@import "blocks/employees_block";
@import "blocks/timeline_block";
@import "blocks/figures_block";
@import "blocks/table_block";
@import "blocks/media_player_block";
@import "blocks/utgefid_efni_block";
@import "blocks/html-block-form";
@import "blocks/image_collection_block";
@import "blocks/image_block";
@import "blocks/episode_block";
@import "blocks/fiftyskills-block";
@import "blocks/secondary_header_block";
@import "blocks/question_list_block";
@import "blocks/related_content_block";
@import "blocks/button_block";
@import "blocks/anchor_link_list_block";
@import "blocks/research_project_block";
@import "blocks/research_project_menu_block";
@import "blocks/image_text_block";
@import "blocks/info_card_block";
@import "blocks/image_card_block";

/* Pages */
@import "pages/blogpage";
@import "pages/homepage";
@import "pages/formpage";
@import "pages/newspage";
@import "pages/published_content_page";
@import "pages/landingpage";
@import "../js/app/components/vorform/form";

/* Vendor */
@import "slick";
@import "lightbox";