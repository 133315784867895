.figures-block {
    padding-bottom: 120px;
    justify-content: center;
    display: flex;
    .figures {
        .figure-container {
            justify-content: center;
            display: flex;
            flex-direction: column;
            margin: auto;

            @include media-breakpoint-down(sm) {
                max-width: 100vw;
            }

            img {
                box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.13);
                @include media-breakpoint-down(sm) {
                    max-width: 100%;
                }
                @include media-breakpoint-down(xl) {
                    max-width: 500px;
                    width: 100%;
                }
            }

            .figure-container-finance {
                justify-content: end;
                display: flex;
                @include media-breakpoint-down(xl) {
                    margin-right: -100px;
                }
            }
            .figure-container-employees {
                justify-content: start;
                display: flex;
                  @include media-breakpoint-down(xl) {
                    margin-left: -100px;
                }
            }
            .figure-container-finance, .figure-container-employees {
                @include media-breakpoint-down(xl) {
                    justify-content: center;
                }
            }

            .button {
                margin-top: 32px;
                display: flex;
                justify-content: center;
                position: relative;
                &:hover {
                    p {
                        &:after {
                            width: 100%;
                            transition: width .3s;

                        }
                    }
                }
                p {
                    display: inline-block;
                    width: auto;
                    font-size: 1.8rem;
                    line-height: 2.2rem;
                    color: $white;
                    padding: 11px 20px 13px;
                    margin: 0px;
                    z-index: 1;
                    position: relative;
                    border-radius: 4px;
                    background-color: unset;
                    border: 1px solid #FFFFFF;
                    border-radius: 4px;
                    &:hover {
                        border: 1px solid $neongreen;
                        transition: border .3s ease-in; 
                    }
                }
            }
            &.finance {
                // margin-right: 60px;
                @include media-breakpoint-down(md) {
                    margin: auto;
                    margin-bottom: 50px;
                }
                .button {
                    p {
                        background-color: unset;
                    }
                }
            }

            &:hover {
                text-decoration: none;
            }
            @include media-breakpoint-down(md) {
                margin: 0 auto;
            }
        }
        @include media-breakpoint-down(xl) {
            padding-bottom: 25px;
            flex-direction: column;
        }
    }
}