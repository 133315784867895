.image-collection-block{
    // max-width: 1280px;
    // padding-left: 20px;
    // padding-right: 20px;
    margin-top: 70px;
    // margin-top: 100px;
    margin-bottom:80px;
    margin-left: auto;
    margin-right: auto;

    img{
        object-fit: contain;
        width: 100%;
    }
    .image-collection-image{
        margin-bottom: 30px;
    }
}