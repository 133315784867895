.news-index-page {
    .title-container {
        position: relative;
        margin-bottom: 100px;
        margin: unset !important;

        .news-year-filter {
            width: 100px;
            position: absolute;
            right: 170px;
            bottom: 40px;

            @include media-breakpoint-down(xl) {
                right: 104px;
            }

            @include media-breakpoint-down(md) {
                right: 80px;
            }

            @include media-breakpoint-down(sm) {
                right: 52px;
                width: 88px;
            }

            @include media-breakpoint-down(xs) {
                right: 24px;
            }

            .year {
                &.desktop {
                    display: block;

                    @include media-breakpoint-down(md) {
                        display: none;
                    }
                }

                &.mobile {
                    display: none;

                    @include media-breakpoint-down(md) {
                        display: block;
                        position: relative;
                        background-color: $white;

                        &:before {
                            content: "";
                            position: absolute;
                            right: 20px;
                            bottom: 0px;
                            width: 11px;
                            height: 100%;
                            background: url(../img/down-arrow.svg) no-repeat;
                            background-position: right center;
                            background-size: 10px;
                            transition: transform .2s ease-out;
                        }

                        &.turn-around {
                            &:before {
                                transform: rotate(-180deg);
                                transition: transform .2s ease-out;
                            }
                        }

                        .selection {
                            font-size: 1.8rem;
                            color: $font-color-primary;
                            font-weight: 400;
                            position: relative;
                            height: 100%;
                            width: 100%;
                            border-radius: 0px;
                            border: none;
                            background-color: transparent;
                            padding: 8px;
                            max-width: 100%;
                            -moz-appearance: none;
                            -webkit-appearance: none;
                            appearance: none;
                        }

                        .selection::-ms-expand {
                            display: none;
                        }

                        .selection:focus {
                            border-color: #aaa;
                            outline: none;
                        }
                    }
                }
            }
        }
    }

    .container-fluid {
        .frettir-container {
            .frettir {
                display: flex;
                flex-wrap: wrap;
                .frett {
                    max-width: 33.33%;
                    align-items: center !important;
                    padding: 27px 24px 16px;
                    flex: 0 0 33.33333%;
                    text-decoration: none;
                    color: $font-color-primary;
                    @include media-breakpoint-up(md) {
                        &:hover {
                            background-color: $white-08;
                            box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.13);
                            img {
                                transition: .5s all ease-in-out;
                                transform: scale(1.02);
                                overflow: hidden;
                                display: block;
                            }
                        }
                    }

                    @include media-breakpoint-down(lg) {
                        max-width: 50%;
                        flex: 0 0 50%;
                    }

                    @include media-breakpoint-down(xs) {
                        max-width: 100%;
                        flex: 0 0 100%;
                    }

                    img {
                        max-width: 100%;
                        width: 100%;
                        overflow: hidden;
                    }

                    .placeholder-img {
                        max-width: 100%;
                        width: 100%;
                        background-color: $brandgreen-d1;
                        aspect-ratio: 1.41887;
                    }

                    .title-container {
                        padding: 20px 0px;
                        height: 90px;

                        p {
                            font-size: 2rem;    
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            box-orient: vertical;
                            -webkit-line-clamp: 2;
                            line-clamp: 2;
                            overflow: hidden;
                            text-overflow: ellipsis;

                            @include media-breakpoint-down(sm) {
                                font-size: 1.8rem;
                            }
                        }
                    }

                    .date-container {
                        border-top: 1px solid $border-color;
                        padding: 16px 16px 16px 0px;
                        width: 100%;

                        p {
                            position: relative;
                            padding: 0px 8px;
                            width: auto;
                            display: inline;

                            &:after {
                                content: "";
                                position: absolute;
                                height: 80%;
                                width: 1px;
                                background: $white;
                                bottom: 2px;
                                right: -6px;
                            }
                        }

                        span {
                            margin-left: 16px;
                        }
                    }
                }
            }
        }
    }
}

body.page-newsindexpage {
    .select2-container--open {
        .select2-dropdown--below {
            width: 100px !important;
            top: 60px;

            @include media-breakpoint-down(sm) {
                width: 88px !important;
            }
        }
    }

    ul.select2-results__options .select2-results__option {
        font-size: 1.8rem;
    }

    .select2-container--default .select2-selection--single {
        border: none;
        background-color: transparent;
    }

    .select2-dropdown ul.select2-results__options {
        width: 100px;
        left: unset !important;

        @include media-breakpoint-down(sm) {
            width: 88px;
        }
    }

    .select2-container--default {
        left: inherit;

        @include media-breakpoint-down(sm) {
            left: unset !important;
            right: 140px;
        }

        @include media-breakpoint-down(xs) {
            right: 112px;
        }
    }

    .select2-container--default .select2-selection--single .select2-selection__arrow {
        right: -8px;
    }
}

.single-news-page {
    .title-container-new {
        padding-bottom: 30px;
        .breadcrumbs {
            ul {
                margin: unset;
            }
        }

        h1 {
            margin-bottom: 25px;
        }

        .date-container {
            width: fit-content;
            width: -moz-fit-content;
            padding-right: 16px;
            justify-content: center !important;
            display: flex;

            p {
                position: relative;
                // padding: 0px 8px;
                width: auto;
                display: inline;

                &:after {
                    content: "";
                    position: absolute;
                    height: 80%;
                    width: 1px;
                    background: $white;
                    bottom: 2px;
                    right: -6px;
                }
            }

            span {
                margin-left: 16px;
            }
        }
    }

    .news-img-box {
        margin: 0px auto;
        margin-bottom: 40px;

        img {
            width: 100%;
            height: auto;
        }

        figcaption {
            @include font-body-tiny ();
            padding-top: 12px;
            color: $font-color-primary;
        }

        &.scaled {
            height: 574px;

            figure {
                height: 100%;
                width: 100%;

                img {
                    object-fit: contain;
                    margin: 0 auto;
                    height: 100%;
                    width: auto;
                }
            }
        }
    }

    .paragraph-block {
        .rich-text {
            ul {
                margin: 0px 40px;
                padding: 32px 0px;
                list-style: none;

                @include media-breakpoint-down(sm) {
                    margin: 0px 10px;
                    padding: 16px 0px;
                }

                li {
                    padding: 8px;

                    &:before {
                        content: "\2022";
                        color: $white;
                        font-weight: bold;
                        display: inline-block;
                        width: 1em;
                        margin-left: -1em;
                    }
                }
            }
        }
    }

    .related-news-container {
        background-color: $brandgreen-d1;
        margin: 80px 0px 40px 60px;
        padding: 40px 0px 40px 180px;
        position: relative;

        @media (max-width: 1500px) {
            padding: 40px 0px 40px 120px;
        }

        @media (max-width: 1400px) {
            padding: 40px 0px 40px 80px;
        }

        @include media-breakpoint-down(sm) {
            margin: 40px 0px 0px;
            padding: 40px 0px 40px 40px;
        }

        @include media-breakpoint-down(xs) {
            padding: 24px 0px 24px 24px;
        }

        .heading {
            text-transform: uppercase;
            font-size: 1.3rem;
            font-weight: 500;
            color: $neongreen;
        }

        .single-news-slider {
            .prev-arrow {
                display: none !important;
            }

            .next-arrow {
                background-image: url(../img/arrow.svg);
                background-repeat: no-repeat;
                background-position: 10% 50%;
                border: none;
                bottom: 191px;
                width: 24vw;
                outline: none;
                position: absolute;
                left: calc(24vw * 3);
                right: 0px;
                top: 48px;
                background-color: transparent;

                @media (max-width: 1400px) {
                    left: calc(33vw * 2);
                }

                @media (max-width: 980px) {
                    left: 50vw;
                    background-position: 20% 50%;
                }

                @include media-breakpoint-down(sm) {
                    left: 60vw;
                }

                @include media-breakpoint-down(xs) {
                    left: 75vw;
                }
            }

            .slick-slide {
                width: 24vw;

                @media (max-width: 1400px) {
                    width: 33vw;
                }

                @media (max-width: 980px) {
                    width: 50vw;
                }

                @include media-breakpoint-down(sm) {
                    width: 60vw;
                }

                @include media-breakpoint-down(xs) {
                    width: 75vw;
                }

                &.next-up {
                    display: block;
                    opacity: .2;
                }

                &.slick-active {
                    opacity: 1;
                    transition: opacity .3s linear .5s;
                }

                .carousel-item {
                    padding-right: 32px;
                    padding-top: 48px;

                    @include media-breakpoint-down(sm) {
                        padding-top: 16px;
                    }

                    .frett-slider {
                        align-items: center !important;
                        text-decoration: none;
                        color: $font-color-primary;

                        &:focus {
                            outline: none;
                        }

                        .single-news-img {
                            width: 100%;
                            height: 256px;
                            overflow-y: hidden;
                            overflow-x: hidden;

                            img {
                                min-width: 100%;
                                min-height: 100%;
                                object-fit: cover;
                            }
                            .placeholder-img {
                                max-width: 100%;
                                width: 100%;
                                background-color: $brandgreen-d1;
                                aspect-ratio: 1.41887;
                            }
                        }

                        .title-container {
                            padding: 24px 0px;
                            height: 120px;

                            @include media-breakpoint-down(lg) {
                                height: 104px;
                            }

                            p {
                                font-size: 2.0rem;

                                @include media-breakpoint-down(lg) {
                                    font-size: 2rem;
                                }
                            }
                        }

                        .date-container-slider {
                            padding: 16px 16px 16px 0px;
                            width: 100%;
                            border-top: 1px solid $white;

                            p {
                                position: relative;
                                padding: 0px 8px;
                                width: fit-content;
                                width: -moz-fit-content;

                                &:after {
                                    content: "";
                                    position: absolute;
                                    height: 60%;
                                    width: 1px;
                                    background: $white;
                                    bottom: 6px;
                                    right: -8px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}