.page-landingpage{
    h1, h2{
        margin-top: 70px !important;
    }
    h3, h4{
        margin-top: 30px !important;
    }
    .bg-container{
        background-position: center !important;
        background-size: cover !important;
        height: 88vh;
        margin-bottom: 60px;
        position: relative;
        .bg-shadow{
            background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 100%);
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }
        .intro-col{
            padding-bottom: 60px;
            @include media-breakpoint-down(md){
                padding-bottom: 30px;
                padding-top: 30px;
            }
            h1{
                @include font-styles(67px, 72px, 0, normal, bold, "TheFuture");
                @include media-breakpoint-down(md){
                    @include font-styles(50px, 60px, 0, normal, bold, "TheFuture");
                }
            }
            h1, h2, h3, h4, p{
                color: $white !important;
            }
            h4{
                @include font-h4;
                font-weight: bold;
                @include media-breakpoint-down(md){
                    @include font-styles(20px, 26px, 0, normal, 500, "TheFuture");
                }
            }
            p{
                @include font-styles(22px, 30px, 0, normal, 300, "TheFuture");
                @include media-breakpoint-down(md){
                    @include font-styles(18px, 26px, 0, normal, 300, "TheFuture");
                }
            }
            svg{
                margin-top: 70px;
                transform: rotate(90deg);     
                path {
                    stroke: $white;
                }
                &:hover{
                    cursor: pointer;
                }
            }
        }
        .breadcrumbs{
            margin-left: -30px;
            margin-top: 80px;
            @include media-breakpoint-down(md){
                margin-left: 0;
            }
            ul li, a{
                color: $white !important;
            }
        }
    }
}