.anchor_link_list_block {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 40px 60px 60px;
    @include media-breakpoint-up(xxl) {
        max-width: 1440px;
        margin: 0 auto;
    }
    div.block-container{
        border-top: solid 10px #3c9dd3;
        box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
        position: relative;
        padding: 24px 0px;
        margin-bottom: 30px;
        div.text-container {
            margin: 0 auto;
            display: table;
            div {
                display: flex;
                flex-direction: row;
                &.top-text {
                    margin-bottom: 8px;
                    display: flex;
                    flex-direction: column;
                    text-align: center;
                    img{
                        width: 100%;
                        max-width: 70px;
                        max-height: 70px;
                        object-fit: contain;
                        margin-left: auto;
                        margin-right: auto;
                        margin-bottom: 10px;
                    }
                }
                p {
                    margin-bottom: 0px;
                    @include media-breakpoint-down(lg) {
                        font-size: 1.6rem;
                    }
                    @include media-breakpoint-down(md) {
                        font-size: 1.8rem;
                    }
                    @include media-breakpoint-down(xs) {
                        font-size: 1.6rem;
                    }
                    a {
                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }
}

.anchor_link_list_block a {
    color: $link-color-secondary;
}