.section-heading-block {
    padding: 80px 0px 65px;
    h2 {
        text-transform: uppercase;
        font-size: 1.3rem;
        font-weight: 500;
        @include font-body-tiny;
        @include media-breakpoint-down(sm) {
            font-size: 1.2rem;
        }
    }
    .intro {
        .rich-text {
            p {
                font-size: 3rem;
                line-height: 3.6rem;
                width: 100%;
                max-width: 700px;
                @include media-breakpoint-down(md) {
                    font-size: 2.6rem;
                    line-height: 3.2rem;
                }
                @include media-breakpoint-down(sm) {
                    font-size: 3.0rem;
                    line-height: 4.0rem;
                }
            }
        }
    }
}