@font-face {
	font-family: 'TheFuture';
	font-weight: 400;
	font-style: normal;
	src: url('../fonts/the-future-regular.woff2') format('woff2'),
}

@font-face {
	font-family: 'TheFuture';
	font-weight: 500;
	font-style: normal;
	src: url('../fonts/the-future-medium.woff2') format('woff2'),
}

@font-face {
	font-family: 'TheFuture';
	font-weight: normal;
	font-style: italic;
	src: url('../fonts/the-future-italic.woff2') format('woff2'),
}

@font-face {
    font-family: 'TheFuture';
    font-weight: 700;
    font-style: normal;
	src: url('../fonts/the-future-bold.woff2') format('woff2'),
}



/* H1 */
@mixin font-h1 () {
    @include font-styles(50px, 54px, 0, normal, 700, "TheFuture");
    color: $font-color-primary;
}
/* H2 */
@mixin font-h2 () {
    @include font-styles(34px, 39px, 0, normal, 700, "TheFuture");
    color: $font-color-primary;
}
/* H3 */
@mixin font-h3 () {
    @include font-styles(28px, 30px, 0, normal, 700, "TheFuture");
}
/* H4 */
@mixin font-h4 () {
    @include font-styles(24px, 30px, 0, normal, 500, "TheFuture");
}
/* H5 */
@mixin font-h5 () {
    @include font-styles(22px, 30px, 0, normal, 400, "TheFuture");
}
/* H6 / Desktop font style */
@mixin font-h6 () {
    @include font-styles(16px, 24px, 0, normal, 700, "TheFuture");
}
/* H7 / Desktop font style */
@mixin font-h7 () {
    @include font-styles(15px, 29px, 0, normal, 500, "TheFuture");
}
/* H8 / Desktop font style */
@mixin font-h8 () {
    @include font-styles(13px, 16px, 0, normal, 500, "TheFuture");
}
/* Tiny body font */
@mixin font-body-tiny () {
    @include font-styles(13px, 18px, 1.2px, normal, 500, "TheFuture");
    color: $neongreen;
}
/* Small body font */
@mixin font-body-small () {
    @include font-styles(16px, 22px, 0, normal, 400, "TheFuture");
    color: $font-color-primary;
}
/* Regular body font */
@mixin font-body-regular () {
    @include font-styles(18px, 30px, 0, normal, 400, "TheFuture");
    color: $font-color-primary;
}
/* Regular body font */
@mixin font-body-medium () {
    @include font-styles(18px, 28px, 0, normal, 500, "TheFuture");
    color: $font-color-primary;
}
/* Large body font */
@mixin font-body-large () {
    @include font-styles(20px, 26px, 0, normal, 400, "TheFuture");
    color: $font-color-primary;
}
/* Inngangur font */
@mixin font-inngangur () {
    @include font-styles(22px, 35px, 0, normal, 400, "TheFuture");
    color: $font-color-primary;
}
@mixin font-label (){
    @include font-styles(13px, 0.5px, normal, 500, "TheFuture");
    color: $font-color-primary;
}