
.select2-container--default {
    position: absolute;
    top: 33px;
    left: 7px;
    
    @include media-breakpoint-down(xs) {
        left: 40px!important ;
    }
    &.select2-container--open {
        .select2-selection--single {
            background-color: $background-color;
        }
    }
    .select2-selection--single {
        background-color: $white-08;
        height: 100%;
        position: relative;
        cursor: pointer;
        display: block;
        user-select: none;
        -webkit-user-select: none; 
        outline:none !important;
        padding: 0px 32px 0px 12px;
        overflow: hidden;


        .select2-selection__rendered {
            line-height: 46px;
            font-weight: 500;
            white-space: nowrap;
        }
        .select2-selection__arrow {
            position: absolute;
            top: 1px;
            right: 1px;
            width: 40px;
            height: 100%;
            right: -3px;
            b {
                display: block;
                width: 100%;
                height: 100%;
                background: url(../img/plain-arrow.svg) no-repeat;
                background-position: center;
                background-size: 10px;
                transform: rotate(-90deg);
                transition: transform .2s ease-out;
            } 
        }
    }
    &.select2-container--open {
        .select2-selection--single {
            .select2-selection__arrow {
                b {
                    transition: transform .2s ease-out;
                    transform: rotate(90deg);
                }
            }
        }
    }
} 


.select2-dropdown {
    background-color: $white;
    box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
    display: block;
    position: absolute;
    z-index: 1;
    top: 8px;
    @include media-breakpoint-down(md) {
        z-index: 10;
    }
    ul.select2-results__options {
        background-color: #365138;
        list-style: none;
        margin: 0;
        user-select: none;
        padding: 0px;
        -webkit-user-select: none;
        @include media-breakpoint-down(xs) {
            width: calc(200% + 20px);
            left: 40px!important ;
        }
        .select2-results__option{
            padding: 8px 8px 8px 12px;
            font-size: 1.4rem;
            &--highlighted[aria-selected] {
                // @include transparent-background($grey-l1, .1);
                background-color: $white-16;

            }
        }
    }
}


.select2-container--open {
    .select2-dropdown--above {
        border-bottom: none;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0; 
        left: 0; 
    }
}

.select2-search--dropdown.select2-search--hide {
    display: none; 
}

.select2-hidden-accessible {
    left: 0px;
    top: 80px;
    border: 0 !important;
    clip: rect(0 0 0 0) !important;
    -webkit-clip-path: inset(50%) !important;
    clip-path: inset(50%) !important;
    height: 1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
    white-space: nowrap !important; 
}

.filter-item {
    position: relative;
}