html {
    font-size: 62.5%;
}

.clamped {
    max-width: 1920px;
}

body {
    @include font-body-regular();
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
    background-color: $background-color;

    &.hamburger-menu-open {
        height: 100vh;
        width: 100vw;
        overflow-y: hidden;

        section {
            visibility: hidden;
        }
    }

    &.pc-filter-open {
        overflow: hidden;
    }

    &.page-homepage {
        section.body-section {
            padding-top: 147px !important;
        }
    }

    section.body-section {
        padding-top: 100px;
        min-height: calc(100vh - 220px);
    }
}

h1 {
    @include font-h1();
}

h2 {
    @include font-h2();

    em {
        font-style: italic;
        color: #64FF00;
    }
}

h3 {
    @include font-h3();

    em {
        font-style: italic;
        color: #64FF00;
    }
}

h4 {
    @include font-h4();

    em {
        font-style: italic;
        color: #64FF00;
    }
}

h5 {
    @include font-h5();
}

h6 {
    @include font-h6();
}

em {
    font-style: italic;
    color: #64FF00;
}

// HM in paragraph block  
.millifyrirsogn {
    text-transform: uppercase;
    color: #64FF00;
    font-weight: 500;
    margin-bottom: 12px;
    letter-spacing: 1.8px;
}

.breadcrumbs {
    margin-top: 28px;

    @include media-breakpoint-down(sm) {
        margin-top: 16px;
    }

    ul {
        list-style: none;
        padding: 0px 0px 16px 0px;

        li {
            color: $font-color-primary;
            display: inline;
            font-size: 1.3rem;

            a {
                color: $font-color-primary;

                &:hover {
                    text-decoration: none;
                    color: $neongreen;
                }
            }
        }
    }
}

.html-block {
    a {
        color: $white;
        text-decoration: none;
        border-bottom: 1px solid $link-underline;

        &:hover {
            color: $neongreen;
            transition: all .2s ease-in;
            border-bottom: 1px solid $link-underline-hover;

        }
    }
}

h4 {
    a {
        color: $white;
        text-decoration: none;
        border-bottom: 1px solid $link-underline;

        &:hover {
            color: $neongreen;
            transition: all .2s ease-in;
            border-bottom: 1px solid $link-underline-hover;

        }
    }
}

.title-container {
    padding: 20px 163px;

    @include media-breakpoint-up(xxl) {
        max-width: 1440px;
        margin: 0 auto;
    }

    @include media-breakpoint-down(lg) {
        padding: 40px 160px;
    }

    @include media-breakpoint-down(md) {
        padding: 40px 60px;
    }

    @include media-breakpoint-down(sm) {
        padding: 40px;
    }

    @include media-breakpoint-down(xs) {
        padding: 40px 24px;
    }

    h1 {
        word-wrap: break-word;

        @include media-breakpoint-down(xl) {
            font-size: 4rem;
        }

        @include media-breakpoint-down(sm) {
            font-size: 4rem;
            line-height: 5rem;
        }
    }

    .intro {
        @include font-inngangur();
    }
}

.title-container-new {
    padding-bottom: 90px;
    padding-top: 30px;

    h1 {
        word-wrap: break-word;

        @include media-breakpoint-down(xl) {
            font-size: 4rem;
        }

        @include media-breakpoint-down(sm) {
            font-size: 4rem;
            line-height: 5rem;
        }
    }

    .intro {
        @include font-inngangur();
    }
}

.header-image-container {
    width: calc(100% - 120px);
    overflow: hidden;
    margin: 0 auto 40px;
    max-width: 1225px;

    @include media-breakpoint-down(md) {
        width: 100%;
    }

    @include media-breakpoint-down(sm) {
        margin: 0px auto 40px;
    }

    img {
        width: 100%;
    }
}

::marker {
    color: $neongreen;
}

ul {
    padding-inline-start: 20px;

    li {
        padding-left: 5px;
    }
}
.pagination{
    .page-item, .page-item.disabled{
        .page-link{
            background-color: rgba(255, 255, 255, 0.08);
            border: $neongreen;
            color: $white;
        }
    }
    .page-item.disabled .page-link{
        color: $grey-l1;
    }
    .page-item.active .page-link{
        background-color: rgba(255, 255, 255, 0.08);
        border-bottom: 1px solid $neongreen;
        color: $neongreen;
    }
}