.question-list-block{
    margin-top: 30px!important;

    .question-block{
        color: $font-color-primary;
        background-color: $brandgreen-d2;
        font-family: "TheFuture";
        padding: 30px;
        margin-bottom: 10px;
        
        .question{
            font-size: 22px;
            font-weight: 400;
            letter-spacing: 0;
            line-height: 26px;
            cursor: pointer;
            position: relative;
            padding-right: 50px;
            span{
                position: absolute;
                float: right;
                right: 10px;
                top: 0;
                svg{
                    width: 24px;
                    height: 24px;
                    vertical-align: top;
                    transition: transform .3s linear;
                    path {
                        fill: $neongreen;
                    
                    }
                }
            }
            &.open{
                svg{
                    transform: rotate(45deg);
                }
            }
        }
        .answer{
            display:none;
            padding-top: 30px;
            font-size: 18px;
            font-weight: 400;
            letter-spacing: 0;
            line-height: 28px;
        }
    }
}