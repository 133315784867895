.table-block {
    // max-width: 1440px;
    // // padding: 40px 320px;
    // padding: 40px 120px;
    // margin: 0 auto;
    // // @include media-breakpoint-down(lg) {
    // //     padding: 40px 160px;
    // // }
    // @include media-breakpoint-down(md) {
    //     padding: 40px 60px;
    // }
    // @include media-breakpoint-down(sm) {
    //     padding: 40px 0px;
    //     margin: 0px 40px;
    // }
    // @include media-breakpoint-down(xs) {
    //     margin: 0px 24px;
    // }
    .title {
        font-size: 3rem;
        font-weight: 100;
        text-align: center;
    }
    .table-container {
        margin-top: 32px;
        padding-bottom: 50px;
        overflow-x: auto;
        table {
            width: 100%;
            font-size: 1.6rem;
            min-width: max-content;
            @include media-breakpoint-down(sm) {
                font-size: 1.4rem;
            }
            thead {
                border-bottom: 1px solid $white-35;
                tr {
                    th {
                        padding: 4px 8px;
                        font-size: 1.6rem;
                        &:last-child(), &:nth-last-child(2){
                            text-align: right;
                        }
                        &.htRight{
                            text-align: right;
                        }
                        &.htCenter{
                            text-align: center;
                        }
                    }
                }
            }
            tbody {
                tr {
                    border-bottom: 1px solid $white-35;
                    &:hover {
                        background-color: $white-08;
                    }
                    td {
                        //text-align: right;
                        padding: 12px 8px;
                        min-width: fit-content;
                        min-width: -moz-fit-content;
                        &:last-child(), &:nth-last-child(2){
                            text-align: right;
                        }
                        // @include media-breakpoint-down(sm) {
                        //     padding: 12px 0px;
                        // }
                        &.htRight{
                            text-align: right;
                        }
                        &.htCenter{
                            text-align: center;
                        }
                    } 
                }
            }
        }
    }
}