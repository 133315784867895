.image-text-block {
    display: flex;
    justify-content: center;

    img {
        min-width: 600px;
    }

    .text-wrapper {
        display: flex;
        justify-content: end;
    }

    .text-container {
        border: 1px solid $white;
        background-color: $brandgreen;
        padding: 40px;
        display: flex;
        flex-direction: column;
        justify-content: end;
        width: 100%;
        min-width: 520px;
        min-height: 300px;

        .rich-text {
            margin-bottom: 30px;
        }

        .button {
            border: 1px solid $white;
            transition: border .3s ease-in;
            border-radius: 4px;
            padding: 8px 14px;
            width: fit-content;
            text-align: center;

            a {
                color: $white;
                font-size: 18px;
                text-decoration: none;
            }

            &:hover {
                border: 1px solid $neongreen;
                text-decoration: none;
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    .image-text-block {
        img {
            min-width: unset;
            margin-bottom: 35px;
        }

        .text-container {
            min-width: unset;
            min-height: unset;
            padding: 60px 40px 40px;

            .rich-text {
                margin-bottom: 20px;
            }
        }
    }
}
@include media-breakpoint-down(xs) {
    .image-text-block {
        .text-container {
            justify-content: center;
            min-width: unset;
            min-height: unset;
            padding: 40px 35px;
        }
    }
}