.research-project-block {
    position: relative;

    .research-block-anchor {
        padding-top: 100px;
        margin-top: -100px;
        height: 15px;
        display: block;
    }

    .research-title {
        padding-top: 75px;
    }

    .rich-text {
        width: 100%;
        padding-bottom: 30px;

        p {
            margin-bottom: 25px;
        }
    }

    .img-container {
        margin-left: 40px;
        img {
            object-fit: contain;
            width: 200%;
            max-width: 380px;
            padding-top: 20px;
        }

        .img-box {
            border: 1px solid #637A66;
            padding: 35px;
            max-width: 300px;
        }
    }
}

.anchor-block {
    padding-top: 100px !important;
    margin-top: -100px;
}

@include media-breakpoint-down(lg) {
    .research-project-block {
        .img-container {
            margin-left: 0;
            margin-bottom: 100px;
        }
    }
}
@include media-breakpoint-down(md) {
    .research-project-block {
        .img-container {
            img {
                max-width: 400px;
                width: 100%;
            }
            .img-box {
                max-width: 320px;
            }
        }
    }
}