.error-block {
    min-height: calc(100vh - 100px);
    position: relative;
    .error-container {
        position: absolute;
        top: 50%;
        transform: translateY(calc(-50% - 50px));
        width: 100%;
        text-align: center;
        .title {
            font-size: 8rem;
            line-height: 8rem;
        }
        .subtitle {
            font-size: 3rem;
            font-weight: 400;
            padding: 20px 0px;
        }
        .links {
            width: fit-content;
            width: -moz-fit-content;
            margin: 40px auto 0px;
            .link{
                display: block;
                padding: 4px 0px;
                color: $font-color-primary;
                border-bottom: 1px solid $link-underline;
                &:hover {
                    border-bottom: 1px solid $link-underline-hover;
                    color: $neongreen;
                    text-decoration: none;
                    cursor: pointer;
                    transition: all .2s ease-in;
                }
            }
        }
    }
}