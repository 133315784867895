.news-overview-block {
    width: calc(100% - 100px);
    padding: 60px 0px 80px 100px;
    margin-top: 40px;
    margin-left: 100px;
    margin-bottom: 135px;
    border: 1px solid $white;
    border-right: none;
    position: relative;
    overflow: hidden;
    @include media-breakpoint-down(md) {
        margin-left: 39px;
        width: calc(100% - 40px);
        padding: 60px 0px 80px 20px;
    }
    @include media-breakpoint-down(sm) {
        border: none;
        margin-bottom: 0;
    }

    .text {
        @include media-breakpoint-down(md) {
            width: calc(100vw - 180px);
        }
        @include media-breakpoint-down(xs) {
            width: 100vw;
        }
        h2 {
            text-transform: uppercase;
            font-size: 1.3rem;
            padding-bottom: 17px;
            @include font-body-tiny;
            @include media-breakpoint-down(sm) {
                font-size: 1.2rem;
            }
        }
        p.description {
            font-size: 2.2rem;
            width: 100%;
            max-width: 700px;
            line-height: 3.0rem;
            padding-bottom:  150px;
            
            @include media-breakpoint-down(sm) {
                font-size: 3.0rem;
                line-height: 4.0rem;
            }
            @include media-breakpoint-down(lg) {
                padding-bottom: 50px;
            }
        }
        ul.sidebar-links {
            list-style: none;
            padding-top: 16px;
            @include media-breakpoint-only(md) {
                padding-inline-start: 40px;
            }
            li {
                position: relative;
                padding: 4px;
                &:before {
                    content: "";
                    background-image: url("../img/link_arrow.svg");
                    background-repeat: no-repeat;
                    height: 10px;
                    width: 20px;
                    position: absolute;
                    left: -40px;
                    top: 50%;
                    transform: translateY(-50%);
                }
                &:hover {
                    &:before {
                        left: -25px;
                        transition: left .3s ease-in-out;

                    }
                }
                
                a {
                    text-transform: uppercase;
                    font-size: 1.3rem;
                    font-weight: 500;
                    text-decoration: none;
                    color: $font-color-primary;
                    &:hover {
                        border-bottom: 1px solid $link-underline-hover;
                        transition: color .2s ease-in;
                    }
                }
            }
        }
    }
    .mobile-sidebar {
        .sidebar-links {
            padding-top: 0 !important;
            flex-flow: column wrap;
            display: flex;
            max-height: 142px;
            column-gap: 46px;
            li:before {
                    left: -23px !important;
                }
            
        }
    }
    .news {
        position: relative;
        @include media-breakpoint-down(lg) {
            padding-top: 80px;
        }
        @include media-breakpoint-down(sm) {
            padding-top: 50px;
            margin-bottom: 100px;
        }
        .slick-list {
            @include media-breakpoint-down(md) {
                padding: 0px 40px;
            }
            @include media-breakpoint-down(sm) {
                padding: 0px;
            }
            .slick-slide {
                width: 600px;
                padding: 0px 40px 0px 0px;
                @include media-breakpoint-down(xl) {
                    width: 500px;
                }
                @include media-breakpoint-down(lg) {
                    width: 60vw;
                }
                @include media-breakpoint-down(sm) {
                    width: 75vw;
                }
                @include media-breakpoint-down(xs) {
                    width: 90vw;
                }
                .news-text {
                    opacity: 0;
                    transition: opacity .2s linear 0s;
                    height: 300px;
                    @include media-breakpoint-down(lg) {
                        height: 340px;
                    }
                    @include media-breakpoint-down(md) {
                        height: 320px;
                    }
                    @include media-breakpoint-down(sm) {
                        height: unset;
                    }

                    .date-container {
                        text-transform: uppercase;
                        font-size: 1.3rem;
                        font-weight: 500;
                        width: -moz-fit-content;
                        width: fit-content;
                        width: -moz-fit-content;
                        padding-right: 16px;
                        margin-bottom: 17px;
                        top: -6px;
                        position: relative;
                        // height: 20px;
                        color: $neongreen;
                        p {
                            display: inline;
                            @include font-body-tiny;
                            &:nth-of-type(1) {
                                position: relative;
                                padding-right: 16px;
                                margin-right: 16px;

                                &:before {
                                    content: "";
                                    position: absolute;
                                    width: 100%;
                                    height: 70%;
                                    bottom: 2px;
                                    border-right: 1px solid $neongreen;
                                }
                            }
                        }
                        @include media-breakpoint-down(sm) {
                            font-size: 1.2rem;
                        }
                    }
                    .title-container {
                        padding: 0px;
                        p {
                            font-size: 3rem;
                            line-height: 3.6rem;
                            font-weight: 400;
                            text-transform: none;
                            @include media-breakpoint-down(sm) {
                                font-size: 2.4rem;
                                line-height: 3rem;
                                margin-bottom: 20px;  
                                height: 70px;
                            }
                        }
                    }
                    a {
                        text-transform: uppercase;
                        font-size: 1.3rem;
                        font-weight: 500;
                        text-decoration: none;
                        color: $neongreen;
                        p {
                            color: $font-color-primary;
                        }
                    }
                }
                .img-container {
                    position: relative;
                    max-width: 100%;
                    width: 100%;
                    height: 300px;
                    overflow: hidden;
                    @include media-breakpoint-down(lg) {
                        height: 320px;
                    }
                    @include media-breakpoint-down(md) {
                        height: 250px;
                    }
                    img {
                        min-width: 100%;
                        min-height: 100%;
                        aspect-ratio: 1.8333;
                    }
                    a{
                        color: $white;
                    }
                }
                &.slick-current {
                    .news-text {
                        opacity: 1;
                        transition: opacity .5s linear .5s;
                        object-fit: cover;
                        width: 100%;
                    }
                    .img-container {
                        img {
                            opacity: 1;
                            transition: opacity .2s linear .5s;
                            object-fit: cover;
                            width: 100%;

                        }
                    }
                }
                &.slick-slide:not(.slick-current){
                    .carousel-item {
                        .img-container {
                            img {
                                opacity: .2;
                                transition: opacity 0s linear .5s;
                                object-fit: cover;
                                width: 100%;

                            }
                        }
                    }
                }
            }
        }
        button.prev-arrow {
            display: none!important;
        }
        button.next-arrow {
            background-image: url(../img/arrow.svg);
            background-repeat: no-repeat;
            background-position: 80px center;
            border: none;
            height: 300px;
            width: calc(100% - 615px);
            outline: none;
            position: absolute;
            left: 615px;
            bottom: 0px;
            background-color: transparent;
            padding: 0px;
            @include media-breakpoint-down(xl) {
                width: calc(100% - 515px);
                background-position: 24px center;
                left: 515px;
            }
            @include media-breakpoint-down(lg) {
                height: 320px;
                left: calc(60vw + 15px);
                width: calc(40vw - 100px - 60px);
            }
            @include media-breakpoint-down(md) {
                height: 200px;
                left: calc(60vw + 15px + 40px);
                width: calc(40vw - 60px - 40px);
            }
            @include media-breakpoint-down(sm) {
                left: calc(15px + 75vw);
                width: calc(25vw);
                height: 640px;
                background-position: 20px calc(100% - 66px);
            }
            @include media-breakpoint-down(xs) {
                left: calc(15px + 90vw - 40px);
                width: calc(10vw + 40px);
                background-size: 40%;
                background-position: calc(100% - 20px) calc(100% - 66px);
            }
        }
        ul.slick-dots{
            list-style-type: none;
            display: flex;
            flex-direction: row;
            padding: 20px 0px;
            position: absolute;
            left: calc(615px - 334px - 40px);
            @include media-breakpoint-down(xl) {
                left: calc(515px - 334px - 40px);
            }
            @include media-breakpoint-down(lg) {
                left: calc(15px + 60vw - 344px - 40px + 10px);
            }
            @include media-breakpoint-down(md) {
                left: calc(15px + 60vw - 344px + 10px);
            }
            @include media-breakpoint-down(sm) {
                left: calc(15px + 75vw - 40px - 264px + 6px);
            }
            @include media-breakpoint-down(xs) {
                left: calc(15px + 90vw - 40px - 264px + 6px);
            }
            li {
                cursor: pointer;
                opacity: 1;
                width: 80px;
                height: 32px;
                position: relative;
                justify-self: center;
                @include media-breakpoint-down(sm) {
                    width: 60px;
                }
                &:after {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%);
                    background-color: $white;
                    width: 60px;
                    height: 4px;
                    @include media-breakpoint-down(sm) {
                        width: 48px;
                    }
                }
                &.slick-active {
                    &:after {
                        background-color: $neongreen;
                    }
                }
                button {
                    display: none;
                }
            }
        }
        
    }
    .paragraph-container{
        @include media-breakpoint-down(sm) {
            display: none;
        }
    }
    .read-more {
        @include media-breakpoint-down(sm) {
            display: none;
        }
    }
}