.employees-block {
    &.employee-list-block{
        .employee-container{
            min-height: 205px;
        }
    }
    display: flex;
    flex-direction: column;
    @include media-breakpoint-up(xl) {
        max-width: 1440px;
        margin: 0 auto;
    }
    .employee-search {
        height: fit-content;
        height: -moz-fit-content;
        margin: 40px 250px 20px;
        position: relative;
        @include media-breakpoint-down(lg) {
            margin: 40px 160px 20px;
        }
        @include media-breakpoint-down(md) {
            margin: 80px 40px 20px;
        }
        @include media-breakpoint-down(sm) {
            margin: 80px 24px 20px;
        }
        label {
            position: absolute;
            display: flex;
            flex-direction: row;
            height: 100%;
            width: auto;
            left: 24px;
            opacity: 1;
            span {
                font-size: 22px;
                line-height: 30px;
                color: $font-color-primary;
            }
            svg {
                fill: $neongreen;
                width: 25px;
                margin-right: 16px;
                margin-bottom: 10px;
            }
            &.on-focus {
                opacity: 0;
            }
        }
        input {
            height: 40px;
            width: 100%;
            border: none;
            border-bottom: 1px solid $grey-l1;
            background-color: transparent;
            z-index: 2;
            padding: 0px 24px 16px;
            border-radius: 0px;
            color: $white;
            &::-webkit-search-decoration,
                &::-webkit-search-cancel-button,
                &::-webkit-search-results-button,
                &::-webkit-search-results-decoration {
                    -webkit-appearance:none;
                }
            &:focus {
                outline: none;
            }
            @include media-breakpoint-down(sm) {
                padding: 0px 8px 8px;
            }
        }
        
    }
    .employees-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 80px 124px;
        position: relative;
        &:before {
            content: "";
            position: absolute;
            width: 100%;
            height: 1px;
            background-color: $white;
            bottom: 80px;
            left: 0;
        }
        // &:after {
        //     content: "";
        //     position: absolute;
        //     width: 1px;
        //     height: calc(100% - 160px);
        //     border: 1px solid #ffffff;
        //     top: 80px;
        //     right: 124px;
        // }
        @include media-breakpoint-down(lg) {
            padding: 80px;
            &:after {
                right: 80px;
            }
        }
        @include media-breakpoint-down(sm) {
            padding: 40px 24px;
            &:before {
                bottom: 40px;
            }
            &:after {
                height: calc(100% - 48px);
                top: 24px;
                right: 24px;
            }
        }
        @include media-breakpoint-down(xs) {
            padding: 24px;
            &:before {
                bottom: 24px;
            }
        }
        .employee-container{
            width: 33.33%;
            padding: 24px;
            display: flex;
            flex-direction: row;
            height: fit-content;
            height: -moz-fit-content;
            @include media-breakpoint-down(xl) {
                width: 50%;
            }
            @include media-breakpoint-down(md) {
                width: 100%;
            }
            @include media-breakpoint-up(md) {
                &:hover {
                    // transform: translate(-1px, -1px);
                    border: none;
                    background-color: $white-06;
                    // box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.13);
                    // padding: 25px;
                    z-index: 2;
                    transition: box-shadow ease-in .2s;
                }
            }
            @include media-breakpoint-down(xs) {
                padding: 20px 0px;
            }
            .employee-nophoto{
                background-color: #f0f0f0;
                min-height: 135px;
                min-width: 135px;
            }
            .employee-img {
                align-self: center;
                @include media-breakpoint-down(xs) {
                    width: 120px;
                    height: 120px;
                }
            }
            .employee-text {
                padding: 16px;
                position: relative;
                .name {
                    font-size: 1.6rem;
                    font-weight: 500;
                    line-height: 2.8rem;
                    margin: 0px;
                    @include media-breakpoint-down(xs) {
                        font-size: 1.4rem;
                    }
                }
                .work {
                    font-size: 1.3rem;
                    line-height: 1.8rem;
                    margin-bottom: 3rem;
                }
                .email {
                    padding-bottom: 2px;
                    position: absolute;
                    bottom: 16px;
                    color: $font-color-primary;
                    font-size: 1.3rem;
                    line-height: 1.8rem;
                    border-bottom: solid 1px rgba($neongreen, .5);
                    display: inline-block;
                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
    }
}