.pc-page-container,
.utgefid-efni-block {
    &.open {
        width: 100vw;

        .title-container {
            display: none;
        }

        .pc-filter-button {
            @include media-breakpoint-down(md) {
                position: fixed;
                top: 54px;
                padding: 0px;
                z-index: 501;
                margin: 0px;
                background-color: transparent;
                width: auto;
                right: -70px;

                a {
                    background-color: transparent;

                    span {
                        opacity: 0;
                        color: $black;
                    }

                    .button-icon {
                        background-image: url(../img/close-filter-white.svg);
                        transform: translateY(4px);
                        background-size: contain;
                        height: 20px;
                        width: 20px;
                    }
                }
            }
        }
    }

    .pc-filter-button {
        display: none;

        @include media-breakpoint-down(md) {
            cursor: pointer;
            display: flex;
            margin-top: 25px;

        }

        @include media-breakpoint-down(sm) {}

        @include media-breakpoint-down(xs) {}

        a {
            display: flex;
            background-color: $neongreen;

            padding: 4px 16px;

            span {
                order: 2;
                color: $black;
                font-size: 1.8rem;
                font-weight: 500;

                @include media-breakpoint-down(sm) {
                    font-size: 1.4rem;
                    line-height: 2.4rem;
                }

            }

            .button-icon {
                order: 1;
                margin-right: 8px;
                margin-top: 2px;
                height: 16px;
                width: 16px;
                background: url(../img/filter.svg) no-repeat;
                transform: translateY(25%);

                @include media-breakpoint-down(sm) {
                    height: 14px;
                    width: 14px;
                    transform: translateY(38%);
                }
            }
        }
    }

    .pc-container {
        padding: 0px 0px 0px 104px;

        @include media-breakpoint-down(md) {
            padding: 0px 40px;
        }

        @include media-breakpoint-down(sm) {
            padding: 0px 24px;
        }

        .filter-title {
            opacity: 0;
            transition: opacity 0s linear;
        }

        .pc-filter-container {
            height: 176px;
            position: relative;
            display: flex;
            flex-direction: row;
            width: 100%;
            padding: 40px 0px 40px 4%;
            flex-wrap: wrap;
            height: auto;

            @media (max-width: 1480px) {
                padding: 40px 120px;
            }

            @include media-breakpoint-down(md) {
                padding: 0px;

                &.desktop {
                    display: none;
                }

                &.mobile {
                    display: block;
                }
            }

            &.desktop {
                &:after {
                    content: "";
                    position: absolute;
                    top: 0px;
                    bottom: 0px;
                    left: 0px;
                    right: 0px;
                    background-color: $white-08;

                    @include media-breakpoint-down(md) {
                        content: none;
                    }
                }

                .filter-item {
                    z-index: 1;
                    width: 200px;
                    width: 180px;
                    height: 44px;
                    margin-right: 50px;
                    margin-bottom: 48px;

                    label {
                        font-family: "TheFuture";
                        font-size: 15px;
                        line-height: 29px;
                        color: $font-color-primary;
                        padding-left: 8px;
                    }

                    select,
                    input {
                        width: 100%;
                        border-radius: 0px;
                        background-color: $white-08;
                        -webkit-appearance: none;
                        padding: 0px 8px;
                    }

                    .select2-selection--single {
                        font-size: 1.4rem;
                    }

                    &.company {
                        order: 1;
                    }

                    &.type {
                        order: 2;
                    }

                    &.name {
                        order: 3;

                        @media (max-width: 1264px) {
                            order: 4;
                        }

                        input {
                            padding: 0px 8px !important;
                            font-size: 1.8rem;
                            color: $font-color-primary;
                            font-weight: 100;
                            height: 44px;
                            border: none;
                            background-color: $white-08;
                        }
                    }

                    &.publisher {
                        order: 4;

                        @media (max-width: 1480px) {
                            order: 5;
                        }
                    }

                    &.year {
                        order: 5;
                        width: 120px;

                        @media (max-width: 1480px) {
                            order: 4;
                        }

                        @media (max-width: 1264px) {
                            order: 3;
                        }
                    }

                    &.button {
                        order: 6;
                        width: 120px;
                        margin-right: 0px;

                        button {
                            // border-radius: 4px;
                            background-color: $neongreen;
                            color: $black;
                            border: none;
                            width: 120px;
                            height: 44px;
                            margin-top: 35px;
                            font-weight: 500;

                            &:focus,
                            &:active {
                                outline: 0;
                            }
                        }
                    }
                }
            }

            &.mobile {
                display: none;
                top: 80px;
                margin-bottom: 80px;
                margin-top: 40px;

                .filter {
                    width: 100%;
                    margin-bottom: 24px;

                    label {
                        color: $white;
                        margin-bottom: 0px;

                    }

                    .select-container {
                        position: relative;
                        background-color: $white-08;

                        &:before {
                            content: "";
                            position: absolute;
                            right: 20px;
                            bottom: 0px;
                            width: 11px;
                            height: 100%;
                            background: url(../img/down-arrow.svg) no-repeat;
                            background-position: right center;
                            background-size: 10px;
                            transition: transform .2s ease-out;
                        }

                        &.turn-around {
                            &:before {
                                transform: rotate(-180deg);
                                transition: transform .2s ease-out;
                            }
                        }
                    }

                    &.name {
                        input {
                            width: 100%;
                            background-color: $white-08;
                            padding: 0px 8px;
                            font-size: 1.8rem;
                            color: $font-color-primary;
                            font-weight: 400;
                            box-shadow: none;
                            -webkit-appearance: none;
                            height: 44px;
                            border: none;
                        }
                    }

                    &.button {
                        width: 375px;
                        margin: 0px auto;

                        @include media-breakpoint-down(sm) {
                            margin: 0px auto 0px;
                        }

                        @include media-breakpoint-down(xs) {
                            width: 100%;
                        }

                        button {
                            width: 100%;
                            height: 44px;
                            margin-top: 24px;
                            // border-radius: 4px;
                            background-color: $neongreen;
                            color: $black;
                            border: none;
                            font-weight: 500;
                        }
                    }
                }

                .selection {
                    font-size: 1.8rem;
                    color: $font-color-primary;
                    font-weight: 400;
                    position: relative;
                    height: 100%;
                    width: 100%;
                    background-color: transparent;
                    padding: 8px;
                    max-width: 100%;
                    -moz-appearance: none;
                    -webkit-appearance: none;
                    appearance: none;
                    border: none;

                }

                .selection::-ms-expand {
                    display: none;
                }

                .selection:focus {
                    border-color: #aaa;
                    outline: none;
                }
            }
        }


        &.open {
            position: fixed;
            z-index: 500;
            height: 100%;
            width: 100vw;
            background-color: $background-color;
            top: 0;

            .filter-title {
                transition: opacity .2s linear;
                opacity: 1;
                font-size: 2.4rem;
                color: $white;
                position: absolute;
                top: 56px;
                font-weight: 500;

                @include media-breakpoint-down(xs) {
                    font-size: 2rem;
                }
            }

            .pc-filter-container {
                &.mobile {
                    display: block;
                }

                &.desktop {
                    display: none;
                }
            }

            .pc-content-container {
                display: none;
            }
        }
    }

    .pc-content-container {
        margin-top: 48px;

        .content-item {
            margin: 10px 0px;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            padding: 20px 32px;
            min-height: 90px;
            max-height: fit-content;
            background-color: $white-08;

            &:hover {
                background-color: $white-16;
                text-decoration: none;

                @include media-breakpoint-up(md) {
                    .text {
                        .download {
                            .svg {
                                fill: $neongreen;
                            }
                        }
                    }
                }

            }
      
            .logo {
                align-self: center;
                // width: 76px;
                max-height: 100%;

                @include media-breakpoint-down(sm) {
                    // align-self: auto;
                    padding-top: 8px;
                    padding-left: 8px;
                }

                @include media-breakpoint-down(xs) {
                    // align-self: auto;
                    // padding-top: 8px;
                    padding-left: 0px;
                }
            }

            .text {
                width: calc(100% - 76px - 20px);
                display: flex;
                flex-direction: row;
                margin-left: auto;

                @include media-breakpoint-down(sm) {
                    flex-wrap: wrap;
                }

                @include media-breakpoint-down(xs) {
                    margin-left: 20px;
                    align-items: center;
                    flex-wrap: wrap;
                }

                .title {
                    align-self: center;
                    margin-bottom: 0;
                    color: $font-color-primary;
                    font-size: 1.6rem;
                    padding: 0px 16px;
                    width: calc(100% - 120px - 25px);

                    @include media-breakpoint-down(sm) {
                        width: 100%;
                        padding: 0px 5px;
                    }

                    @include media-breakpoint-down(xs) {
                        font-size: 1.4rem;

                    }
                }

                .date {
                    align-self: center;
                    margin-bottom: 0;
                    color: $font-color-primary;
                    font-size: 16px;
                    margin: 0 auto;

                    @include media-breakpoint-down(sm) {
                        margin: 0px 0px 0px 8px;
                    }

                    @include media-breakpoint-down(xs) {
                        order: -1;
                        // margin: 0;
                        font-size: 1.4rem;
                    }
                }

                .download {
                    margin-top: 10px;

                    @include media-breakpoint-down(sm) {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: nowrap;
                        margin-left: auto;
                        margin-top: 0;

                    }

                    @include media-breakpoint-down(xs) {
                        // margin-left: 8px;
                        right: -40px;
                        position: relative;
                    }

                    .text {
                        display: none;

                        @include media-breakpoint-down(sm) {
                            display: block;
                            margin: 0 0 0 auto;
                            align-self: end;
                            color: $white;
                            font-size: 1.4rem;
                            width: auto;
                            margin-right: 8px;
                            white-space: nowrap;
                        }
                    }

                    .svg {
                        width: 25px;
                        height: 25px;
                        align-self: center;
                        margin: 0 auto;
                        fill: $white;

                        @include media-breakpoint-down(sm) {
                            margin: 0 16px 7px auto;
                            align-self: end;
                            width: 20px;
                            height: 20px;
                            margin-top: 4px;
                        }
                    }
                }
            }
        }
    }

    .content-item-button {
        color: $white;
        border-bottom: 1px solid $link-underline;

        &:hover {
            color: $neongreen;
            border-bottom: 1px solid $link-underline-hover;
            transition: all .2s ease-in;
            text-decoration: none;
        }
    }
}

.utgefid-efni-block {
    .pc-content-container {
        margin-top: 0;
        .content-item {
            margin: 16px 0px;

        }
    }
}