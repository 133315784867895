button.button-block {
    background-color: $neongreen;
    border: none;
    display: block;
    min-width: 223px;
    height: 50px;
    border-radius: 4px;
    text-decoration: none;
    position: relative;
    -webkit-appearance: none;
    cursor: pointer;

    margin: 50px auto 70px auto;
    // height: 68px;
    // width: 270px;
    &:after {
        content: "";
        position: absolute;
        width: 0%;
        height: 100%;
        border-radius: 4px;
        background-color: #5ce600;
        transition: width .3s;
        left: 0px;
        top: 0px;
    }

    &:hover {
        cursor: pointer;

        &:after {
            width: 100%;
            transition: width .3s;
        }
    }

    a {
        border: none;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;

        @include media-breakpoint-down(sm) {
            margin-top: 8px;
        }

        span {
            color: $black;
            position: absolute;
            z-index: 5;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;
            text-align: center;
            font-weight: 500;

            @include media-breakpoint-down(sm) {
                font-size: 1.6rem;
            }
        }
    }
}