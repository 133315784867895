.video-block {
    padding: 40px 320px;
    @include media-breakpoint-up(xxl) {
        max-width: 1440px;
        margin: 0 auto;
    }
    @include media-breakpoint-down(lg) {
        padding: 40px 160px;
    }
    @include media-breakpoint-down(md) {
        padding: 40px 60px;
    }
    @include media-breakpoint-down(sm) {
        padding: 40px;
    }
    @include media-breakpoint-down(xs) {
        padding: 40px 24px;
    }
    video {
        max-width: 100%;
    }
}