header {
    height: 100px;
    position: fixed;
    background-color: $background-color;
    min-width: 100vw;
    z-index: 10;
    transform: translateY(0px);
    transition: top .3s, height .3s, transform .3s;
    .background-div {
        opacity: 0;
        position: absolute;
        background-color: $background-color;
        width: 0;
        top: 46px;
        transition: opacity .2s linear, width 0s .5s linear, top 0s .5s linear;
        z-index: -2;
        right: -60px;
    }
    .header-container {
        width: 100%;
        height: 100%;
        .navbar-nav {
            top: calc(100% - 28px);
            transition: top .3s;
        }
    }
    &.header-hamburger-open {
        height: 100%!important;
        transition: height 0s!important;
    }
    &.nav-up {
        transform: translateY(-100%);
        transition: transform .3s;
    }

    &.no-trans {
        transition: height 0s!important;
    }
    &.open {
        .background-div {
            opacity: 1;
            transition: opacity .2s linear;
            z-index: 0;
            width: calc(100% + 60px);
            height: calc(100vh - 53px);
        }
        nav.navbar {
            .navbar-container{
                .navbar-nav {
                    .nav-item {
                        .child-nav-container {
                            &.active {
                                ul.child-nav {
                                    transition: opacity .2s .1s ease-out !important;
                                    overflow: hidden;
                                    // overflow: scroll;
                                    height: calc(100vh - 100px);
                                    opacity: 1;
                                    border: 1px solid $border-color;
                                    border-right: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    //Only on Homepage
    &.large-menu {
        height: 147px;
        transition: height .3s;
        nav.desktop {
            height: 147px;
            padding: 32px 60px 24px;
            transition: height .3s;
            .navbar-logo{
                img {
                    // height: 60px;
                    transition: height .3s;
                    max-width: 455px;
                    top: -20px;
                }
            }
            .navbar-container {
                .search {
                    transition: top .3s;
                    top: -8px;
                    &:after { 
                        bottom: -62px;
                    }
                    &.active {
                        .nav-link {
                            padding-bottom: 8px;
                            svg {
                                fill: white !important;
                            }
                        }
                    }
                }
                .english {
                    top: 0px;
                }
                .search-input {

                    &.active {
                        height: 74px;
                        transition: height .3s;
                    }
                }
                .navbar-nav {
                    top: calc(100% - 38px);
                    transition: top .3s;
                }
            }
        }
        &.open {
            .background-div {
                top: 53px;
                height: calc(100vh - 53px);
            }
            nav.navbar {
                .navbar-container{
                    .navbar-nav {
                        .nav-item {
                            .child-nav-container {
                                &.active {
                                    ul.child-nav {
                                        height: calc(100vh - 100px);
                                        overflow: hidden;
                                        // overflow: scroll;
                                        transition: opacity .2s .1s ease-out !important;
                                        opacity: 1;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        &.nav-up {
            transform: translateY(-100%);
            transition: all .3s;
        }
        &.nav-down {
            transform: translateY(0%);
        }
        &.header-hamburger-open {
            transition: height 0s!important;
            .header-container{
                .hamburger {
                    &.open {
                        .navbar-toggler {
                            top: 60px;
                        }
                        .hamburger-content {
                            .english {
                                margin-top: 54px;
                            }
                        }
                    }
                }
            }
        }
    }
}
nav.desktop{
    position: fixed;
    background-color: $background-color;
    min-width: 100%;
    display: flex;
    flex-direction: row;
    height: 100px;
    padding: 8px 60px 16px;
    z-index: 500;
    @media (max-width: 1024px) {
        display: none;
    }
    .navbar-logo{
        width: 30%;
        position: relative;
        margin-right: 40px;
        img{
            // height: 48px;
            position: absolute;
            top: -8px;
            transition: height .3s .3s;
            max-width: 455px;
        }
    }
    .navbar-container {
        width: calc(70% - 60px);
        height: 72px;
        position: relative;
        .navbar-nav{
            width: 100%;
            display: flex;
            flex-direction: row;
            list-style: none;
            position: absolute;
            bottom: 0px;
            transition: bottom .3s .3s;
            &.hide {
                display: none;
            }
            &.hover {
                .green-line {
                    &>span.nav-link{
                        border-bottom: 4px solid $neongreen!important;
                        padding-bottom: 17px;

                    }
                    span.nav-link, a.nav-link {
                        border-bottom: none;
                        &:hover {
                            border-bottom: solid 2px $neongreen;    
                        }
                    }
                    .child-nav-container {
                        span.nav-link, a.nav-link {
                            border-bottom: none;
                        }
                    }
                }
            }
            &:not(.hover) {
                .green-line {
                    &>span.nav-link{
                        border-bottom: 4px solid $neongreen!important;
                    }
                    span.nav-link, a.nav-link {
                        border-bottom: solid 2px $neongreen;
                    }
                    .child-nav-container {
                        span.nav-link, a.nav-link {
                            border-bottom: none;
                        }
                    }
                }
            }
            .nav-item{
                cursor: pointer;
                margin-right: 40px;
                span.nav-link, a.nav-link {
                    display: inline;
                    text-decoration: none;
                    color: $font-color-primary;
                    padding: 8px 0px;
                    white-space: nowrap;     

                    &:hover {
                        border-bottom: solid 4px $neongreen;
                        padding-bottom: 17px;
                    }
                    &.active {
                        border-bottom: solid 4px $neongreen!important;
                        padding-bottom: 17px;
                        z-index: 500;
                        position: relative;
                    }
                }
                ul {
                    display: inline;
                    opacity: 0;
                    height: 0px;
                    width: 0px;
                    padding: 0px;
                    li {
                        opacity: 0;
                        height: 0px;
                        width: 0px;
                    }
                }
            }
            .child-nav-container {
                &:not(.active) {
                    .child-menu-item {
                        max-width: 50%;
                        margin-bottom: 3rem;
                        @include media-breakpoint-down(md){
                            max-width: unset;
                        }
                        .nav-link {
                            display: none;
                        }
                    }
                }
                &.active {
                    ul.child-nav {
                        z-index: 10;
                        width: calc(100% + 59.8px);
                        top: 46px;
                        display: block;
                        position: absolute;
                        list-style: none;
                        left: 0px;
                        right: auto;
                        padding: 40px 56px;
                        opacity: 0;
                        transition: opacity .3s .5s linear;
                        &.active {
                            .grandchild-background-div {
                                width: 50%;
                                left: 50%;
                                transition: all .2s ease-out;
                            }
                        }
                        .grandchild-background-div {
                            width: 0%;
                            left: 100%;
                            min-height: 100vh;
                            position: absolute;
                            top: 0;;
                            background-color: $brandgreen-d1;
                            border-left: 1px solid $border-color;
                            transition: all .2s ease-out;
                        }
                        li.child-menu-item {
                            display: table;
                            width: auto;
                            cursor: pointer;
                            height: auto;
                            z-index: unset;
                            opacity: 1;
                            transition: opacity 0s .4s linear;
                            animation: list 300ms ease-out;
                            max-width: 50%;
                            margin-bottom: 3rem;
                            &:hover {
                                .nav-link {
                                    &:before {
                                        content: "";
                                        position: absolute;
                                        left: -35px;
                                        top: 5px;
                                        height: 20px;
                                        width: 23px;
                                        background-color: transparent;
                                        background-size: contain;
                                        background-image: url(../img/link_arrow_new.svg);
                                        background-repeat: no-repeat;
                                        transform: rotate(180deg);
                                    }
                                }
                            }
                            @include media-breakpoint-down(md){
                                max-width: unset;
                            }
                            @for $i from 1 through 10 {
                                &:nth-child(#{$i}) {
                                  animation-delay: 100ms * $i;
                                  transition-delay: 100ms * $i;
                                }
                            }
                            &.parent.menu-active{
                                .nav-link.active{
                                    padding-left: 0;
                                }
                            }
                            &:not(.parent) {
                                &:hover {
                                    .nav-link {
                                        &:before {
                                            content: "";
                                            position: absolute;
                                            left: -35px;
                                            top: 5px;
                                            height: 20px;
                                            width: 23px;
                                            background-color: transparent;
                                            background-size: contain;
                                            background-image: url(../img/link_arrow_new.svg);
                                            background-repeat: no-repeat;
                                            transform: rotate(180deg);
                                        }
                                    }
                                }
                            }
                            &.not-hover{
                                span, a {
                                    opacity: 0.75;
                                }
                            }
                            &.active {
                                span, a {
                                    opacity: 1!important;
                                    font-weight: 400;
                                    padding-bottom: 6px !important;

                                }
                            }
                            .nav-link {
                                position: relative;
                                font-size: 18px !important;
                                white-space: break-spaces;
                                line-height: 3.2rem;
                                font-weight: 100;
                                color: rgba(white, .9);
                                margin-left: 4px;
                                @media (max-width: 1440px) {
                                    font-size: 2.8rem;
                                }
                                @include media-breakpoint-down(lg){
                                    font-size: 2.4rem;
                                }
                                &:before {
                                    content: "";
                                    position: absolute;
                                    left: -19px;
                                    top: 21px;
                                    height: 1px;
                                    width: 16px;
                                    transform: translate(-100%, -50%);
                                    background-color: $neongreen;
                                }
                                &:hover {
                                    font-weight: 400;
                                    opacity: 1;
                                    border-bottom: 2px solid $link-underline-hover;
                                }
                            }
                            ul {
                                display: none;
                            }
                            .grandchild-nav-container {
                                height: 0;
                                width: 0;
                                .grandchild-nav {
                                    display: inline-block;
                                    width: 0%;
                                    height: 0%;
                                    .grandchild-menu-item {
                                        opacity: 0;
                                        transition: opacity 0s .5s linear;
                                        display: inline-block;
                                        width: 0%;
                                        height: 0%;
                                        a {
                                            display: none;
                                        }
                                    }
                                }
                                &.active {
                                    height: auto;
                                    width: auto;
                                    span {
                                        color: rgba(white, 1);
                                        font-weight: bold;
                                        padding-top: 8px;
                                        &:before {
                                            height: 3px;
                                        }
                                    }
                                    .grandchild-nav {
                                        opacity: 1;
                                        position: absolute;
                                        list-style: inside;
                                        display: block;
                                        width: 50%;
                                        height: calc(100vh - 100px);
                                        overflow: hidden;
                                        // overflow-y: scroll;
                                        // overflow-x: hidden;
                                        padding: 56px 0px 56px 56px;
                                        top: 0;
                                        left: 50%;
                                        .grandchild-menu-item {
                                            display: table;
                                            width: fit-content;
                                            width: -moz-fit-content;
                                            height: auto;
                                            padding: 0px 0px 16px 0px;
                                            cursor: pointer;
                                            color: $white;
                                            opacity: 1;
                                            transition: opacity 0s .2s linear;
                                            animation: list 300ms ease-out;
                                            @for $i from 1 through 10 {
                                                &:nth-child(#{$i}) {
                                                    animation-delay: 100ms * $i;
                                                    transition-delay: 100ms * $i;
                                                }
                                            }
                                            a {
                                                display: block;
                                                padding: unset;
                                            }
                                            &:hover {
                                                transition-delay: 0s;
                                            }
                                            &.not-hover{
                                                opacity: 0.63;
                                                transition: opacity 0s 0s!important;
                                            }
                                            .nav-link {
                                                font-size: 2.4rem;
                                                line-height: 4rem;
                                                font-weight: 100;
                                                white-space: break-spaces;
                                                @media (max-width: 1440px) {
                                                    font-size: 2.2rem;
                                                    line-height: 3.5rem;
                                                }
                                                &:before {
                                                    content: "";
                                                    position: absolute;
                                                    left: -16px;
                                                    top: 19px;
                                                    height: 6px;
                                                    width: 6px;
                                                    transform: translate(-100%, -50%);
                                                    background-color: $neongreen;
                                                    border-radius: 50%;
                                                }
                                                &:hover {
                                                    font-weight: 400;
                                                    opacity: 1;
                                                    transition: all 0s 0s!important;
                                                }
                                            }
                                            a {
                                                color: $white;
                                                &:hover {
                                                    border: none;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .search-input {
            display: none;
            z-index: 500;
            &.active {
                display: flex;
                flex-direction: row;
                width: 88%;
                position: relative;
                margin-top: 8px;
                // margin-top: 32px;
                height: 44px;
                transition: height .3s;
                flex-wrap: nowrap;
                &:not(.page-homepage){
                    height: 103px;
                }
                .search-lable {
                    font-size: 2.4rem;
                    color: $white;
                    padding-left: 16px;
                    line-height: 4rem;
                    align-self: center;
                }
                .search-blue {
                    cursor: text;
                    color: $white;
                    font-size: 2.4rem !important;
                    padding-left: 16px;
                    align-self: center;
                    @include media-breakpoint-down(sm) {
                        font-size: 1.8rem;
                    }
                }
                
                input {
                    align-self: center!important;
                    width: calc(100% - 190px);
                    border: none;
                    align-self: end;
                    background-color: transparent;
                    font-size: 2.4rem;
                    color: $font-color-primary;
                    padding-left: 16px;
                    line-height: 3.7rem;
                    fill: white;
                    &:focus {
                        outline: none;
                    }
                    &::-webkit-search-decoration,
                    &::-webkit-search-cancel-button,
                    &::-webkit-search-results-button,
                    &::-webkit-search-results-decoration {
                        -webkit-appearance:none;
                    }
                }
                label {
                    cursor: text;
                    position: absolute;
                    bottom: 0px;
                    left: 16px;
                    font-size: 2.4rem;
                    transform: translateX(100%);
                    height: 100%;
                    line-height: 3.7rem;
                    margin-bottom: 0;
                    display: flex;
                    span {
                        color: $grey-l1;
                        align-self: center;
                    }
                }
            }
            &.on-focus {
                label {
                    display: none;
                }
            }
        }
        .search{
            position: absolute;
            right: 0;
            top: 0px;
            cursor: pointer;

            &:after {
                content: "";
                position: absolute;
                width: 0%;
                height: 2px;
                bottom: -72px;
                right: -60px;
                background-color: $neongreen;
            }
            .nav-link {
                padding: 0px;
                svg{
                    height: 32px;
                    width: 20px;
                    padding-bottom: 4px;
                    fill: $font-color-primary;
                }
            }
            .close-button {
                display: none;
            }
            &.active {
                width: 100%;
                right: 0px;
                text-align: right;
                &:after {
                    width: calc(100% + 60px);
                }
                .nav-link {
                    padding-bottom: 8px;
                    .search-text {
                        display: none;
                    }
                }
                #search-logo {
                    display: none;
                }
                .close-button {
                    display: block;
                    float: right;
                    position: absolute;
                    top: 6px;
                    right: 0px;
                }
            }
        }
        .english{
            position: absolute;
            right: 47px;
            top: 8px;
            text-transform: uppercase;
            @include font-h8();

            .nav-link{
                padding: 0px;
                color: $neongreen;
                img{
                    vertical-align: text-top;
                    
                }
            }
        }
    }
    .results-container {
        position: absolute;
        top: 94px;
        right: 0px;
        display: none;
        width: 100%;
        max-width: calc(70vw - 82px);
        height: 75vw;
        max-height: calc(83vh + 47px);
        background-color: $brandgreen-d2;
        &.show {
            display: block;
            .search-results {
                padding: 24px 48px 0px;
                max-height: calc(100% - 32px);
                overflow-y: scroll;
                position: relative;
                .result {
                    width: 492px;
                    margin: 0 auto;
                    padding: 24px 0px;
                    border-bottom: 1px $grey-l1 dashed;
                    p {
                            font-weight: 500 !important;
                            font-size: 18px !important;
                        }
                    h3 {
                        font-weight: normal;
                        display: inline-block;
                        margin-right: 10px;
                    }
                    span.document-icon svg{
                        width: 25px;
                        vertical-align: baseline;
                    }
                    
                    a {
                        text-transform: uppercase;
                        font-weight: 500;
                        font-size: 1.3rem;
                        color: $neongreen;
                    }
                }
            }
            .close-button {
                position: absolute;
                right: 60px;
                top: 32px;
                z-index: 5;
            }
        }
    }
    .close-button {
        height: 20px;
        width: 20px;
        span {
            width: 100%;
            height: 2px;
            border-radius: 2px;
            background-color: $white;
            display: block;
            transform: rotate(45deg);
            top: 9px;
            left: 0px;
            position: relative;
            &:nth-child(2) {
                transform: rotate(-45deg);
                top: 7px;
            }
        }
    }
}

.page-homepage {
    .header-desktop {
        .desktop {
            .results-container{
                top: 110px!important;
                max-height: 100vh!important;
            }
        }
    }
}
    
.hamburger {
    display: none;
    @media (max-width: 1024px) {
        min-width: 100%;
        height: 100%;
        position: relative;
        padding: 16px 60px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        @include media-breakpoint-down(sm) {
            padding: 16px 40px;
        }
        @include media-breakpoint-down(xs) {
            padding: 16px 24px;
        }
        .navbar-brand{
            height: fit-content;
            height: -moz-fit-content;
            width: auto;
            margin: 0;
            align-self: center;
            margin-bottom: 4px;
            img{
                @include media-breakpoint-down(sm) {
                    height: 40px;
                    width: 225px;
                }
            }
        }
        button.navbar-toggler{
            font-size: 1.25rem;
            line-height: 1;
            background-color: transparent;
            z-index: 5;
            padding: 0px;
            align-self: center;
            &:focus {
                outline: none;
            }
            @include media-breakpoint-up(lg) {
                &:hover {
                    span.navbar-toggler-icon {
                        span:nth-child(1) {
                            width: 24px;
                            right: 3px
                        }
                        span:nth-child(4) {
                            width: 24px;
                            right: 3px;
                        }
                    }
                }
            }
            span.navbar-toggler-icon{
                width: 30px;
                height: 20px;
                cursor: pointer;
                position: relative;
                left: 0;
                &.open {
                    span {
                        &:nth-child(1) {
                            top: 11px;
                            width: 0% !important;
                            left: 50%;
                        }
                        &:nth-child(2) {
                            -webkit-transform: rotate(45deg);
                            -moz-transform: rotate(45deg);
                            -o-transform: rotate(45deg);
                            transform: rotate(45deg);
                            top: calc(50% - 2px);
                            left: -1px;
                        }
                        
                        &:nth-child(3) {
                            -webkit-transform: rotate(-45deg);
                            -moz-transform: rotate(-45deg);
                            -o-transform: rotate(-45deg);
                            transform: rotate(-45deg);
                            top: calc(50% - 2px);
                            left: -1px;
                        }
                        
                        &:nth-child(4) {
                            top: 11px;
                            width: 0% !important;
                            left: 50%;
                        }
                    }
                }
                span {
                    background: $white;
                    display: block;
                    position: absolute;
                    height: 3px;
                    width: 100%;
                    border-radius: 9px;
                    opacity: 1;
                    right: 0;
                    transition: .20s ease-in-out;
                    &:nth-child(1) {
                        top: 0px;
                    }
                    &:nth-child(2), &:nth-child(3) {
                        top: 8px;
                    }
                    
                    &:nth-child(4) {
                        top: 16px;
                    }
                }
            }
        }
        .hamburger-content {
            display: none;
        }
        &.open {
            background-color: $background-color;
            z-index: 3;
            position: absolute;
            display: block;
            span.navbar-toggler-icon.open {
                span {
                    background: white;
                }
            }
            .navbar-brand {
                display: none;
            }
            .navbar-toggler {
                width: 30px;
                height: 30px;
                position: absolute;
                margin: 0;
                top: 34px;
                right: 60px;
                @include media-breakpoint-down(md) {
                    right: 60px;
                }
                @include media-breakpoint-down(sm) {
                    right: 40px;
                }
                @include media-breakpoint-down(xs) {
                    right: 26px;
                }
                .navbar-toggler-icon {
                    display: block;
                }
            }
            .hamburger-content {
                display: block;
                color: white;
                .english {
                    width: fit-content;
                    width: -moz-fit-content;
                    margin-left: 53px;
                    margin-top: 34px;
                    a {
                        color: $neongreen;
                        position: relative;
                        text-transform: uppercase;
                        @include font-h8();
                        svg {
                            height: 20px;
                            fill: white;
                            top: 50%;
                            transform: translate(-260%, -60%);
                            position: absolute;
                        }
                    }
                }
                .navbar-nav {
                    width: auto;
                    padding: 0px 20px;
                    position: absolute;
                    top: 0px;
                    left: 50%;
                    transform: translate(-50%, 132px);
                    @include media-breakpoint-down(sm) {
                        left: 5%;
                        transform: translate(0%, 132px);
                        width: 100%;
                    }
                    .nav-link {
                        padding-left: 8px;
                        &.active {
                            font-weight: 600;
                            @include media-breakpoint-down(xs) {
                                font-size: 2.6rem;
                            }
                        }
                    }
                    &:not(.menu-active) {
                        height: fit-content;
                        height: -moz-fit-content;
                        li.nav-item {
                            .nav-link {
                                &:before {
                                    content: "";
                                    position: absolute;
                                    left: 0;
                                    top: 50%;
                                    transform: translateY(-50%);
                                    height: 1px;
                                    width: 20px;
                                    background-color: $neongreen;
                                    @include media-breakpoint-down(xs) {
                                        width: 16px;
                                        left: 4px;
                                    }
                                }
                            }
                        }
                    }
                    li.nav-item {
                        display: block;
                        position: relative;
                        cursor: pointer;
                        font-size: 2.8rem;
                        line-height: 5rem;
                        padding: 8px 0px 8px 40px;
                        @include media-breakpoint-down(sm) {
                            padding: 0px 0px 0px 40px;
                        }
                        @include media-breakpoint-down(xs) {
                            font-size: 2.6rem;
                            line-height: 4rem;
                        }
                        a {
                            text-decoration: none;
                            color: white;
                        }
                        ul.child-nav {
                            display: none;
                        }
                        &.hide {
                            display: none;
                        }
                        
                    }
                    &.menu-active {
                        max-height: 560px;
                        min-height: 20vh;
                        @include media-breakpoint-down(sm) {
                            max-height: 400px;
                        }
                        li.nav-item .nav-link{
                            &:not(.active){
                                display: none;
                            }
                            &.active{
                                position: relative;
                                
                                &:before {
                                    content: "";
                                    margin-left: -20px;
                                    position: absolute;
                                    left: -40px;
                                    top: 23px;
                                    height: 20px;
                                    width: 40px;
                                    background-image: url("../img/link_arrow_new.svg"); 
                                    background-color: transparent;
                                    background-repeat: no-repeat;
                                    @include media-breakpoint-down(xs) {
                                        left: -28px;
                                        width: 28px;
                                        top: 17px;
                                    }
                                }
                                .show {
                                    display: block;
                                }
                            }
                        }
                        div.child-nav-container {
                            transform: translateX(-50px);
                            &.overflow {
                            // overflow-x: hidden;
                            max-height: 500px;
                                @include media-breakpoint-down(sm) {
                                    max-height: 360px;
                                }
                            }
                        }
                        ul.child-nav{
                            // transform: translateX(50px);
                            display: none;
                            &.scroll {
                                max-height: calc(100vh - 300px);
                                overflow-y: scroll;
                            }
                            &.show {
                                display: block;
                                // padding: 0px;
                                list-style: none;
                                @include media-breakpoint-down(sm) {
                                    max-height: 350px;
                                    overflow-y: scroll;  
                                    padding-left: 97px;                                
                                }
                                li.child-menu-item:not(.menu-active) {
                                    .nav-link {
                                        display: block;
                                        position: relative;
                                        font-size: 2.4rem;
                                        line-height: 4.4rem;
                                        @include media-breakpoint-down(xs) {
                                            font-size: 2rem;
                                            line-height: 3.4rem;
                                        }
                                        &:before {
                                            content: "•";
                                            position: absolute;
                                            left: -40px;
                                            top: 50%;
                                            transform: translateY(-50%);
                                            // height: 1px;
                                            // width: 20px;
                                            color: $neongreen;
                                            @include media-breakpoint-down(xs) {
                                                width: 16px;
                                                left: -36px;
                                            }
                                        }
                                    }
                                    ul.grandchild-nav {
                                        display: none;
                                    }
                                    &.hide {
                                        display: none;
                                    }
                                }
                                li.child-menu-item:nth-of-type(1) {
                                    &:not(.menu-active) {
                                        padding-top: 20px;
                                    }
                                }
                                li.child-menu-item.menu-active {
                                    position: relative;
                                    span.nav-link {
                                        &:before {
                                            content: "";
                                            margin-left: -11px;
                                            position: absolute;
                                            top: 23px;
                                            height: 20px;
                                            width: 40px;
                                            background-image: url("../img/link_arrow_new.svg"); 
                                            background-color: transparent;
                                            background-repeat: no-repeat;
                                            @include media-breakpoint-down(xs) {
                                                left: -28px;
                                                width: 28px;
                                                top: 17px;
                                            }
                                        }
                                    }
                                    &.parent{
                                        .nav-link.active{
                                            padding-left: 0;
                                        }
                                        .grandchild-nav-link.active{
                                            padding-left: 16px;
                                            margin-left: -57px;
                                        }
                                    }
                                    div.grandchild-nav-container {
                                        // overflow-x: hidden;
                                        transform: translateX(-50px);
                                        max-height: 500px;
                                        @include media-breakpoint-down(sm) {
                                            max-height: 360px;
                                            transform: translateX(-92px);
                                        }
                                        ul.grandchild-nav {
                                            li { 
                                                padding-left: 0px;
                                            }
                                            &.scroll {
                                                max-height: calc(100vh - 300px);
                                                overflow-y: scroll;
                                            }
                                            &.show {
                                                transform: translateX(50px);
                                                display: block;
                                                list-style: none;
                                                font-size: 2.4rem;
                                                line-height: 4.4rem;
                                                @include media-breakpoint-down(xs) {
                                                    font-size: 2rem;
                                                    line-height: 3.4rem;
                                                }
                                                @include media-breakpoint-down(sm) {
                                                    max-height: 350px;
                                                    overflow-y: scroll; 

                                                }
                                                .grandchild-menu-item {
                                                    position: relative;
                                                    .nav-link {
                                                        display: block;
                                                        &:before {
                                                            content: "•";
                                                            position: absolute;
                                                            left: -40px;
                                                            top: 50%;
                                                            transform: translateY(-50%);
                                                            color: $neongreen;
                                                            @include media-breakpoint-down(xs) {
                                                                width: 16px;
                                                                left: -36px;
                                                            }
                                                        }
                                                    }
                                                    &:nth-of-type(1) {
                                                        margin-top: 20px;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .search {
                    cursor: pointer;
                    align-items: center;
                    position: absolute;
                    left: 50%;
                    bottom: 29px;
                    width: 50%;
                    text-align: unset;
                    transform: translateX(-50%);
                    height: 64px;
                    background-color: $brandgreen-d2;
                    box-shadow: 0px 2px 40px rgba(#000000, .1);
                    @include media-breakpoint-down(sm) {
                        width: 90%;
                    }
                    a {
                        font-size: 2.8rem;
                        padding: 20px;
                        @include media-breakpoint-down(sm) {
                            font-size: 2rem;

                        }
                        svg{
                            width: 32px;
                            fill: $neongreen;
                            right: 20px;
                            position: absolute;
                        }
                    }
                }
            }
        }
    }
}





@keyframes list {
    0% {
      opacity: 0;
    }
    
    100% {
      opacity: 1;
    }
}