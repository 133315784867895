// =============================================================================
// String Replace
// =============================================================================

@function str-replace($string, $search, $replace: "") {
	$index: str-index($string, $search);

	@if $index {
		@return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
	}

	@return $string;
}

// =============================================================================
// Font Face
// =============================================================================

@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff ttf svg) {
	$src: null;

	$extmods: (
		eot: "?",
		svg: "#" + str-replace($name, " ", "_")
	);

	$formats: (
		otf: "opentype",
		ttf: "truetype"
	);

	@each $ext in $exts {
		$extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
		$format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
		$src: append($src, url(quote("../fonts/" + $path + "." + $extmod)) format(quote($format)), comma);
	}

	@font-face {
		font-family: quote($name);
		font-style: $style;
		font-weight: $weight;
		src: $src;
	}
}

@function calculateRem($size) {
    $remSize: $size / 10px;
    @return $remSize * 1rem;
}

@mixin both-font-sizes($size) {
    font-size: $size;
    font-size: calculateRem($size);
}

@mixin line-height($size) {
    line-height: $size;
    line-height: calculateRem($size);
}

@mixin font-styles($font-size, $line-height, $letter-spacing, $font-style, $font-weight, $font-family) {
    @include both-font-sizes($font-size);
    @include line-height($line-height);
    letter-spacing: $letter-spacing;
    font-style: $font-style;
    font-weight: $font-weight;
    font-family: $font-family;
}
