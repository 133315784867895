.financial-figures-block {
    .figures-container {
        border: 1px solid $border-color;
        // width: calc(100% - 240px);
        margin: 50px 0;
        padding: 54px;
        max-width: 1400px;

        @include media-breakpoint-down(md) {
        padding: 30px;
        }
        @include media-breakpoint-down(sm) {
        padding: 20px;
        }

        h2 {
            text-align: center;
            font-size: 3rem;
            line-height: 3.2rem;
            font-weight: 400;
            color: $font-color-primary;
            @include media-breakpoint-down(xs) {
                margin: 0;
            }
        }
        .figures-content-container {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            padding-top: 32px;
            margin: 0 auto;
            @include media-breakpoint-down(xs) {
                margin: 0;
            }
            .figure-container {
                border-left: solid $white 1px;
                padding: 0px 40px;
                width: 20%;
                min-width: 200px;
                margin-bottom: 24px;
                .figure-title {
                    @include font-body-tiny;
                    // font-size: 1.3rem;
                    // line-height: 1.8rem;
                    padding-top: 8px;
                    // color: $font-color-primary;
                }
                .figure-number {
                    font-size: 3rem;
                    line-height: 3.6rem;
                    padding-bottom: 8px;
              
                    @include media-breakpoint-down(lg) {
                        font-size: 2.8rem;
                    }
                    @include media-breakpoint-down(md) {
                        font-size: 2.2rem;
                    }
                }
            }
        }
    }
}

.figure-chart-block {
    .charts-container {
        display: flex;
        flex-direction: row;
        max-width: 1280px;
        margin: 0 auto 40px;
       
        .chart-container{
            box-shadow: 0 10px 40px 0 rgba(0,0,0,0.1);
            padding: 16px;
        }
    }
}