div.form-container {
  padding: 0px;

  .star-info {
    @include media-breakpoint-down(sm) {
      svg {
        width: 10px;
      }
    }

    p {
      display: inline;

      @include media-breakpoint-down(sm) {
        font-size: 1.3rem;
      }
    }
  }

  .title-container-new {
    a:not(.breadcrumbs-link) {
      margin: 40px 0px 0px;
      display: inline-block;
      text-decoration: none;
      color: $font-color-primary;
      border-bottom: 1px solid $link-underline;


      &:hover {
        border-bottom: 1px solid $link-underline-hover;
        transition: all .2s ease-in;

      }
    }
  }

  .sub-info {
    display: flex;
    justify-content: space-between;

    a {
      color: $font-color-primary;
      border-bottom: 1px solid $link-underline;
      text-decoration: none;

      &:hover {
        border-bottom: 1px solid $link-underline-hover;
        transition: all 0.2s ease-in;
      }
    }

    .star-info {
      align-self: flex-end;

      svg g g {
        fill: $neongreen;
      }
    }
  }

  .above-form {
    @include media-breakpoint-down(sm) {

      .question {
        font-size: 16px;
        font-weight: 400;
      }
    }
  }

  .form {
    padding: 67px 0;
    .form-sent-text {
      background-color: $white-08;
      padding: 22px;
      margin-top: -45px;
      p {
        text-align: center;
      }
    }

    .form-group {
      width: 100%;
      min-height: 80px;
      // min-height: 90px;
      margin: 30px auto;
      // margin: 40px auto;
      position: relative;

      &:after {
        content: "";
        position: absolute;
        height: 90px;
        background-position: center;
        background-repeat: no-repeat;
        width: 24px;
        top: 0px;
        right: 40px;
        opacity: 0;
        transition: opacity 0.2s cubic-bezier(0.86, 0.16, 0.88, 0.84);

        @include media-breakpoint-down(md) {
          right: 20px;
        }
      }

      @include media-breakpoint-down(sm) {
        height: auto;
        margin: 24px auto;
        min-height: 70px !important;

        &:not(.single) {
          .checkbox-container {
            margin-bottom: 12px;
          }
        }
      }

      .help-text {
        // display: block;
        // font-size: 14px;
        // color: rgb(160, 160, 160);

        // @include media-breakpoint-down(sm) {
        //   font-size: 12px;
        // }
        display: none;
      }

      &.focused,
      &.has-value {
        svg {
          top: 12px !important;
          transition: top 0.13s linear;

          @include media-breakpoint-up(lg) {
            width: 12px;
          }
        }

        .help-text {
          display: none;
        }
      }

      &:not(.input):not(.email):not(.radio):not(.checkbox):not(.file) {
        border: none;
        // display: none;
        justify-content: center;
        display: flex;
        @include media-breakpoint-down(sm) {
          svg {
            left: 10px !important;
          }
        }

        label {
          position: absolute;
          right: 16px;
          left: 2px;
          top: 18px;
          padding: 0px 40px;

          @include media-breakpoint-down(sm) {
            left: 2px;
            padding: 0px 28px !important;
          }
        }
      }

      &.input,
      &.email {
        svg {
          top: 35px;
          transition: top 0.13s linear;

          g g {
            fill: $neongreen;
          }

          @include media-breakpoint-down(md) {
            top: 26px;
          }
        }

        label {
          top: 24px;
          transition: top 0.13s linear;

          @include media-breakpoint-down(md) {
            top: 17px;
          }
        }
      }

      svg {
        position: absolute;
        top: 55px;
        left: 20px;
        z-index: 2;

        @include media-breakpoint-down(md) {
          top: 57px;
          width: 10px;
        }

        @include media-breakpoint-down(sm) {
          left: 8px;
          top: 15px;
        }
      }

      input {
        position: absolute;
        padding-top: 28px !important;
        color: $font-color-primary;

        &.focus+label {
          top: 2px !important;
          padding: 20px 40px 8px !important;
          transform: translate(0px);
          color: $grey-l1;
          font-size: 1.4rem;
          left: 0;
        }
      }

      &.file {
        label {
          top: 4px !important;
          padding: 2px 40px 8px !important;
          transform: translate(0px);
          color: $grey-l1;
          font-size: 1.4rem;
          left: 0;
        }

        svg {
          top: 12px !important;

          @include media-breakpoint-up(lg) {
            width: 12px;
          }
        }

        .help-text {
          display: none;
        }

        input {
          padding-top: 39px !important;
        }
      }

      textarea {
        min-height: 80px;
        width: 100%;
        max-height: 300px;
        border: none;
        padding: 66px 80px 30px 40px;
        background-color: $white-08;
        color: $white;

        @include media-breakpoint-down(md) {
          padding: 55px 60px 30px 40px;
        }

        @include media-breakpoint-down(sm) {
          padding: 55px 60px 30px 28px;
        }

        &:focus+label {
          background-color: transparent !important;
          top: -10px !important;
          transition: top 0.13s linear;
          padding: 20px 40px 8px !important;
          transform: translate(0px);
          color: $grey-l1;
          font-size: 1.4rem;

          @include media-breakpoint-down(md) {
            top: 8px !important;
          }
        }

        &.focus+label {
          top: 2px !important;
          transition: top 0.13s linear;
          padding: 20px 40px 8px !important;
          transform: translate(0px);
          color: $grey-l1;
          font-size: 1.4rem;
        }
      }

      &.valid {
        &:after {
          background-image: url(../img/check.svg);
          opacity: 1;
          transition: opacity 0.1s ease-out;
        }
      }

      &.invalid {
        border: solid 2px rgba(#de1818, 0.5);

        &:after {
          background-image: url(../img/invalid-email.svg);
          opacity: 1;
          transition: all 0.1s ease-out;
          width: 20px;
        }

        textarea {
          border: solid 2px rgba(#de1818, 0.5);
        }
      }

      &.checkbox {
        height: fit-content;
        height: -moz-fit-content;
        padding: 12px 40px;

        @include media-breakpoint-down(sm) {
          padding: 8px 24px !important;
        }

        &>label {
          position: unset;

          @include media-breakpoint-down(sm) {
            line-height: 20px !important;
            margin-bottom: 10px;
          }
        }

        svg {
          top: 20px;
          transition: top 0.13s linear;

          g g {
            fill: $neongreen;
          }
        }

        &.single {
          padding: 12px 40px;
          height: auto !important;
          background-color: $white-08;
          display: flex;
          align-items: center;

          .checkbox-container {
            span {
              top: 18px !important;
            }

            label {
              left: unset !important;
              // margin-top: 0 !important;
              padding-left: 40px;
              position: relative;
            }
          }
        }

        .checkbox-container {
          min-height: 40px;
          position: relative;
          height: auto !important;

          @include media-breakpoint-down(sm) {}

          input {
            opacity: 0;

            &:checked~span {
              border-color: $neongreen;
              background-image: url("../img/check-green.svg");
              background-size: 70%;
              background-position: center;
              background-repeat: no-repeat;
            }
          }

          span {
            height: 24px;
            width: 24px;
            border: 1px solid $grey-l1;
            display: block;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            z-index: -1;
          }

          label {
            left: 40px;
            margin-top: 0.5rem;

            @include media-breakpoint-down(sm) {
              position: relative;
              left: unset !important;
              line-height: 22px;
              padding-left: 40px;
            }
          }
        }
      }

      &.radio {
        width: 100%;
        display: flex;
        flex-direction: column;
        height: fit-content;
        height: -moz-fit-content;
        padding: 48px 40px;

        @include media-breakpoint-down(sm) {
          flex-direction: column;
          height: auto;
          padding: 8px 24px;
        }

        &>label {
          position: unset;
        }

        .radio-container {
          margin: 8px 0;
          height: fit-content;
          height: -moz-fit-content;
          position: relative;
          margin-left: 20px;

          @include media-breakpoint-down(sm) {
            margin: 6px 0;
          }

          input {
            opacity: 0;
            color: $white;

            &:checked+label::after {
              background-color: $neongreen;
            }

            &:checked+label::before {
              border: 1.5px solid $neongreen;
              background-color: transparent;
            }
          }

          &.disabled {
            color:  rgba(255, 255, 255, 0.5);
          }
  
          label {
            left: 20px;
            margin-bottom: 0px;
            margin-right: 80px;
            position: relative;
            top: 0px;
            transform: translateY(0);
            background-color: transparent;
            // color: $white;
            font-size: 18px;
          
            @include media-breakpoint-down(sm) {
              left: 32px;
              line-height: 20px;
            }

            &::before {
              content: "";
              position: absolute;
              width: 28px;
              height: 28px;
              border: 1px solid lightgray;
              border-radius: 50%;
              background-color: #fff;
              margin-left: -40px;

              @include media-breakpoint-down(sm) {
                width: 24px;
                height: 24px;
                top: 4px;
                margin-left: -36px;
              }
            }

            &::after {
              position: absolute;
              content: " ";
              width: 14px;
              height: 14px;
              left: 7px;
              top: 7px;
              border-radius: 50%;
              background-color: white;
              margin-left: -40px;
              @include media-breakpoint-down(sm) {
                width: 16px;
                height: 16px;
                left: 8px;
                top: 8px;
              }
            }
            &.disabled {
              &::before{
               background-color: rgba(255, 255, 255, 0.3);
               border-color: transparent;
             }
             &::after {
              content: none;
             }
            }
          }
        }
      }

      input[type="text"]:focus+label,
      input[type="email"]:focus+label,
      input.has-content+label {
        top: 4px !important;
        // top: 2px !important;
        color: $grey-l1;
        font-size: 1.4rem;

        @include media-breakpoint-down(sm) {
          top: 16px;
          padding-top: 4px;
        }
      }

      textarea.has-content+label {
        top: -14px !important;
        background-color: transparent !important;
        padding: 24px 40px 8px !important;
        transform: translate(0px);
        color: $grey-l1;
        font-size: 1.4rem;

        @include media-breakpoint-down(sm) {
          top: 9px !important;
        }
      }

      textarea:focus,
      textarea.has-content {
        outline: none;
        padding-top: 35px !important;
        padding-bottom: 42px !important;

        @include media-breakpoint-down(sm) {
          padding-bottom: 0px;
          padding-top: 52px;
        }
      }

      input {
        width: 100%;
        height: 100%;
        background: $white-08;
        border: none;
        padding: 0px 80px 0px 40px;

        @include media-breakpoint-down(md) {
          padding: 0px 60px 0px 40px;
        }

        @include media-breakpoint-down(sm) {
          padding: 0px 60px 0px 28px;
        }
      }

      input[type="text"]:focus,
      input[type="email"]:focus,
      input.has-content {
        outline: none;
        padding-top: 28px;

        @include media-breakpoint-down(sm) {
          padding-top: 25px !important;
        }
      }

      label {
        position: absolute;
        left: 40px;

        @include media-breakpoint-down(sm) {
          font-size: 1.6rem;
          left: 28px;
        }
      }
    }

    input.btn {
      width: 94px;
      height: 48px;
      font-weight: 500;
      background-color: $neongreen;
      border-radius: 4px;
      font-size: 18px;
      color: $black;
      display: block;
      margin: 0 auto;
    }
  }

  .max-submit {
    background-color: $white-08;
    padding: 22px;
    margin-top: 25px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    p {
      text-align: center;
    }
  }
}
