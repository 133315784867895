.image-block{
    padding: 0px 320px;
    @include media-breakpoint-up(xxl) {
        max-width: 1440px;
        margin: 0 auto;
    }
    @include media-breakpoint-down(lg) {
        padding: 0px 80px;
    }
    @include media-breakpoint-down(md) {
        padding: 0px 60px;
    }
    @include media-breakpoint-down(sm) {
        padding: 0px 40px;
    }
    @include media-breakpoint-down(xs) {
        padding: 0px 24px;
    }
    img{
        max-width: 100%;
        height: auto;
    }
}