
// $font-color-primary: #595959;

$pink-ljosleidari: #ec008c;
$blue-subsidiary: #57BFD4;
$blue: #3c9dd3;
$blue-d1: #2679A6;
$blue-d2: #2D2A26;
$blue-bg: #EBF3F6;
$carbfix: #085393;

$pink: #D33C91;
$yellow: #F0B54F;

$orange-l1: #E88700;
$orange: #F37721;

$black: #000000;

$grey-d1: #2d2a26;
$grey-d2: #595959;
$grey: #494643;
$grey-l1: #b7b7b7;
$grey-l2: #ebf3f6;

$white-d2: #f9f9f9;
$white-d1: #fcfcfc;
$white: #ffffff;

$white-35: rgba(255, 255, 255, .35);
$white-25: rgba(255, 255, 255, .25);
$white-06: rgba(255, 255, 255, 0.06);
$white-08: rgba(255, 255, 255, 0.08);
$white-16: rgba(255, 255, 255, 0.16);

$brandgreen: #0F3214;
$brandgreen-d1:#0D2D12;
$brandgreen-d2: #0C2810;
$brandgreen-d2-50: rgb(12, 40, 16, .5);

$neongreen: #64FF00;
$neongreen_70: rgba(100, 255, 0, .7);
$neongreen_50: rgba(100, 255, 0, .5);

$background-color: $brandgreen;
$font-color-primary: $white;
$font-color-secondary: $grey-d2;
$link-color-secondary: $font-color-primary;

$link-underline: $neongreen_50;
$link-underline-hover: $neongreen;

$border-color: $white;
$border-color-secondary: $white-35;

@mixin transparent-color($color, $transparency) {
    color: rgba($color, $transparency);
}
@mixin transparent-background($background, $transparency) {
    background-color: rgba($background, $transparency);
}