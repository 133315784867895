.related-content-block{
    margin-top: 60px;
    .outer-container{
        background-color: $white-08;
        padding: 56px 180px;
        @include media-breakpoint-down(md){
            padding: 38px 26px;
        }
        .title{
            color: $neongreen !important;
            font-weight: 500 !important;
            margin-bottom: 34px;
            text-transform: uppercase;
            @include font-body-tiny;

        }
        h4{
            font-weight: 400 !important;
            margin-top: 20px;
            @include font-inngangur;
        }
        a{
            @include font-body-small;
            color: $white;
            text-decoration: none;
            border-bottom: 1px solid $neongreen;
            &:hover{
                color: $neongreen;
                transition: color .2s ease-in;
            }
        }
    }
}