#vorform {
    .form-group{
        margin-bottom: 2.5rem;
    }
    input, select, button{
        font-size: 16px;
    }
    select, input{
        padding: 12px;
        height: auto;
        background-color: #FCFCFC;
        border: 1px solid #DEDEDE;
        // height: 46px;
        color: #5C5C5C;
    }
    textarea{
        padding: 21px 17px;
        height: auto;
        background-color: #FCFCFC;
        border: 1px solid #DEDEDE;
        height: 292px;
        color: #5C5C5C;
        font-size: 16px;
    }
    datalist {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        font-size: 10px;
    }
    label{
        font-size: 1.6rem;
        &.required::after {
            content: '*';
            font-size: 2rem;
            margin-left: 3px;
        }
    }
    .label-strong{
        font-size: 1.8rem;
        font-weight: 500;
        margin-top: 2rem;
        display: inline-block;
        &+.infopopup{
            margin-top: 29px;
        }
    }
    .form-check{
        margin-bottom: 10px;
        input[type="radio"]{
            display: none;
            &+label{
                cursor: pointer;
                margin-left: 24px;
                &:before{
                    content: '';
                    width: 24px;
                    height: 24px;
                    border-radius: 50%;
                    border: 1px solid #D6D6D6;
                    position: absolute;
                    left: 0px;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
            &:checked{
                &+label{
                    &:before{
                        border-color: $neongreen;
                    }
                    &:after{
                        content: '';
                        width: 14px;
                        height: 14px;
                        background-color: $white;
                        border-radius: 50%;
                        left: 5px;
                        top: 50%;
                        transform: translateY(-50%);
                        position: absolute;
                    }
                }
            }
        }
        input[type="checkbox"]{
            display: none;
            &+label{
                cursor: pointer;
                margin-left: 24px;
                &:before{
                    content: '';
                    width: 24px;
                    height: 24px;
                    border: 1px solid #D6D6D6;
                    position: absolute;
                    left: 0px;
                    top: 3px;
                }
            }
            &:checked{
                &+label{
                    &:before{
                        border-color: $neongreen;
                    }
                    &:after{
                        content: url("data:image/svg+xml,%3Csvg width='17px' height='16px' viewBox='0 0 17 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EF72331AC-E741-4490-B516-26A67F640CED%3C/title%3E%3Cg id='ymislegt' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Umsóknarform' transform='translate(-323.000000, -4885.000000)' fill='%23FFFFFF' fill-rule='nonzero'%3E%3Cg id='noun_checkmark_364805-copy' transform='translate(323.000000, 4885.000000)'%3E%3Cpolygon id='Path' points='5.54365 15.88125 0.0637 10.2816 2.1126 8.27645 5.25945 11.4926 13.74625 0.21875 16.0363 1.94285'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                        width: 14px;
                        height: 14px;
                        left: 4px;
                        top: 3px;
                        position: absolute;
                    }
                    &.required {
                        &:after {
                            top: 0;
                            left: 2px;
                        }
                    }
                }
            }
        }
    }
    button{
        font-weight: 500;
        border-radius: 4px;
        padding: 0.5rem 1.35rem;
    }
    table{
        thead{
            th{
                border: none!important;
                font-size: 1.6rem;
                font-weight: normal;
                padding-left: 0!important;
                padding-right: 1.5rem;
                width: calc(33.333% - 7px);
                &:last-child{
                    width: 21px;
                    padding-right: 0;
                }
                color: $white;
            }
        }
        tbody{
            td{
                padding-left: 0!important;
                padding-right: 1.5rem;
                width: calc(33.333% - 7px);
                &:last-child{
                    width: 21px;
                    padding-right: 0;
                    svg{
                        margin-top: 10px;
                        cursor: pointer;
                        path {
                            fill: $black;
                        }
                    }
                }
                color: #5C5C5C;
            }
        }
    }

    .btn {
        background-color: $neongreen !important;
        border-color: $neongreen !important;
        color: $black;
    }
    .wordcount{
        font-size: 1.4rem;
        span{
            color: $neongreen;
            &.too-long{
                color: red;
            }
        }
    }
    .infopopup{
        padding: 0;
        border: 0;
        background: transparent;
        position: absolute;
        right: 15px;
        outline: none;
        height: 16px;
        margin-top: 9px;
        svg{
            vertical-align: top;
            fill: $white;
        }
    }
    input[type="file"] {
        outline: none;
        padding: 4px;
        margin: -4px;
        background-color: transparent;
        border: none;
        &.form-control-file {
            color: white;
        }
      }
      
      input[type="file"]::before {
        top: 16px;
      }
      
      input[type="file"]::after {
        top: 14px;
      }
      
      /* ------- From Step 2 ------- */
      
      input[type="file"] {
        position: relative;
      }
      
      input[type="file"]::file-selector-button {
        width: 136px;
        color: transparent;
      }
      
      input[type="file"]::after {
        position: absolute;
        pointer-events: none;
        /*   top: 10px; */
        left: 16px;
        height: 20px;
        width: 20px;
        content: "";
      }

      @supports (-moz-appearance: none) {
        input[type="file"]::file-selector-button {
          color: #0964b0;
        }
      }
      
      /* ------- From Step 1 ------- */
      
      /* file upload button */
      input[type="file"]::file-selector-button {
        border-radius: 4px;
        padding: 0 16px;
        height: 40px;
        cursor: pointer;
        background-color: $neongreen !important;
        color: $black;
        font-weight: 500;
        border: none;
        box-shadow: none;
        margin-right: 16px;
        transition: background-color 200ms;
        border-radius: 4px;
      }
      
      /* file upload button hover state */
      input[type="file"]::file-selector-button:hover {
        background-color: #2b89be;
      }
      
      /* file upload button active state */
      input[type="file"]::file-selector-button:active {
        background-color: #2b89be;
      }
      
      /* ------------------------ */
}
.popover-body{
    font-size: 14px;
}