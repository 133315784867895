.search-page {
    background-color: $background-color;
    z-index: 50;
    &.fixed-position {
        padding-top: 160px;
        @include media-breakpoint-down(sm) {
            padding-top: 148px;
        }
    }
    .search-container {
        width: 100%;
        height: 160px;
        position: relative;
        background-color: $background-color;
        &.z-index {
            z-index: 0;
        }
        @include media-breakpoint-down(sm) {
            height: 148px;
        }
        &.fixed-position {
            position: fixed;
            top: 0px;
            height: 180px;
            background-image: linear-gradient(rgba(255,255,255,1) 80%, rgba(255,255,255,0));
            background-color: transparent;
            @include media-breakpoint-down(sm) {
                background-image: linear-gradient(rgba(255,255,255,1) 70%, rgba(255,255,255,0));
            }
            .search-input {
                top: calc(50% - 16px); 
            }
        }
        .search-input-hamburger{
            width: calc(100% - 120px);
            height: 80px;
            background-color: $brandgreen-d2;
            box-shadow: 0px 2px 40px rgba(0, 0, 0, 0.15);
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: flex;
            flex-direction: row;
            // justify-content: space-between;
            @include media-breakpoint-down(sm) {
                width: calc(100% - 56px);
                height: 60px;
            }
            .search-blue {
                cursor: text;
                color: $white;
                font-size: 2.4rem !important;
                padding-left: 16px;
                align-self: center;
                white-space: nowrap;
                @include media-breakpoint-down(sm) {
                    font-size: 1.8rem !important;
                }
            }
            input {
                padding: 0px 16px;
                width: calc(100% - 185px);
                height: 100%;
                border: none;
                background-color: transparent;
                font-size: 2.4rem;
                color: $font-color-primary;
                &::-webkit-search-decoration,
                &::-webkit-search-cancel-button,
                &::-webkit-search-results-button,
                &::-webkit-search-results-decoration {
                    -webkit-appearance:none;
                }
                @include media-breakpoint-down(sm) {
                    font-size: 1.8rem;
                    padding: 0px 12px;
                    width: calc(100% - 143px);
                }
                &:focus {
                    outline: none;
                }
            }
            label {
                cursor: text;
                font-size: 2.4rem;
                position: absolute;
                transform: translateX(100%);
                padding: 0px 6px;
                align-self: center;
                margin-bottom: 0;
                @include media-breakpoint-down(sm) {
                    font-size: 1.8rem;
                    padding: 0px 21px;
                }
                span {
                    color: $grey-l1;
                }
            }
            &.on-focus {
                label {
                    display: none;
                }
            }
        }
    }
    .results-container {
        background-color: $brandgreen-d2;
        width: calc(100% - 120px); 
        margin: 0 auto;
        position: relative;
        @include media-breakpoint-down(sm) {
            width: calc(100% - 60px);
        }    
          &:before {
            content: "";
            position: absolute;
            width: 100%;
            height: 2px;
            background-color: $neongreen;
        }
        .result {
            padding: 24px;
            border-bottom: dashed 1px $grey-l1;
            @include media-breakpoint-down(sm) {
                padding: 24px 8px;
            }
            h3 {
                font-weight: 400;
                margin-bottom: 8px;
                @include media-breakpoint-down(md) {
                    font-size: 22px;
                }
            }
            span.document-icon {
               margin-left: 10px;   
                svg{
                    width: 25px;
                    .cls-1, .cls-2 {
                        stroke: $neongreen;
                    }
                }
            }
            .text {
                position: relative;
                max-height: 140px;
                overflow-y: hidden;
                p {
                    font-weight: unset !important;
                    font-size: 18px !important;
                    @include media-breakpoint-down(md) {
                        font-size: 16px !important;
                } }
                &:after {
                    content: "";
                    height: 60px;
                    width: 100%;
                    position: absolute;
                    bottom: 0px;
                }
            }
            a {
                color: $neongreen !important;
                text-transform: uppercase;
                font-weight: 500;
                font-size: 1.3rem;
                color: $neongreen;
            }
            &:last-child {
                &:after {
                    content: "";
                    position: absolute;
                    bottom: 0px;
                    width: 100%;
                    left: 0;
                    height: 2px;
                    background-color: $neongreen;
                }
            }
        }
    }
}
