.paragraph-block{
    &.intro-paragraph{
        @include font-inngangur;
    }
    h2 {
        @include font-h2();
        margin-top: 30px;
        margin-bottom: -16px;
        @include media-breakpoint-down(sm) {
            font-size: 3.2rem;
        }
    }
    h3{
        @include font-h3();
        font-weight: 500;
        margin-top: 30px;
        @include media-breakpoint-down(sm) {
            font-size: 2.4rem;
        }
    }
    h4 {
        @include font-h4();
        margin-top: 30px;
        margin-bottom: -16px; 
    }
    
    h5, h6 {
        margin-top: 30px;
        margin-bottom: -16px;
    }
    .rich-text{
        .full-width {
            width: 100%;
            height: auto;
        }
        b{
            font-weight: 700;
        }
        i{
            font-style: italic;
        }
        figure.img-height{
            text-align: center;
            max-width: 100%;
            height: auto;
        }
        figure:not(.full-width):not(.img-height){
            position: relative;
            max-width: 50%;
            display: flex;
            flex-direction: column;
            z-index: -1;
            @include media-breakpoint-down(md) {
                width: calc(100% - 40px)!important;
                max-width: unset;
            }
            @include media-breakpoint-down(sm) {
                width: calc(100vw - 80px) !important;
            }
            @include media-breakpoint-down(xs) {
                width: calc(100vw - 24px) !important;
            }
            &.right{
                margin: 40px 0px 80px 80px;
                float: right;
                &:before{
                    content: '';
                    border: 1px solid $border-color-secondary;
                    position: absolute;
                    width: 300%;
                    height: 100%;
                    transform: translate(-40px, 40px);
                    z-index: -1;
                }
                @include media-breakpoint-down(md) {
                    margin: 28px 0px 80px 0px;
                }
                @include media-breakpoint-down(xs) {
                    &:before {
                        right: 0;
                        width: 100vw;
                        left: unset;
                        transform: translate(0px, 40px);
                    }
                }
                @media (max-width: 524px) {
                    &:before {
                        transform: translate(24px, 24px);
                    }
                }
                .img-container {
                    img {
                        left: 0px;
                        @include media-breakpoint-down(xs) {
                            right: 0;
                        }
                    }
                }
                &.nobox {
                    margin: 40px 0px 80px 65px;
                    &::before {
                        content: none;
                    }
                }
            }
            &.left{
                margin: 40px 80px 80px 0px;
                float: left;
                &:before{
                    content: '';
                    border: 1px solid $border-color-secondary;
                    position: absolute;
                    right: 0;
                    width: 400%;
                    height: 100%;
                    transform: translate(40px, 40px);
                    z-index: -1;
                }
                @include media-breakpoint-down(md) {
                    margin: 28px 0px 80px 0px;
                }
                @include media-breakpoint-down(xs) {
                    &:before {
                        left: -24px;
                        width: 100vw;
                        right: unset;
                        transform: translate(0px, 40px);
                    }
                }
                @media (max-width: 524px) {
                    &:before {
                        transform: translate(-24px, 24px);
                    }
                }
                .img-container {
                    img {
                        right: 0px;
                        @include media-breakpoint-down(xs) {
                            left: 0;
                        }
                    }
                }
                &.nobox {
                    margin: 40px 65px 80px 0px;
                    &::before {
                        content: none;
                    }
                }
            }
            .img-container {
                height: 322px;
                min-width: 400px;
                position: static;
                @include media-breakpoint-down(md) {
                    height: auto!important;
                    min-width: 100%;
                }
                img {
                    position: absolute;
                    max-width: 600px;
                    height: auto;
                    @include media-breakpoint-down(md) {
                        max-width: unset;
                        width: 100%;
                        position: static;
                    }
                    @include media-breakpoint-down(xs) {
                        max-width: calc(100vw - 24px);
                    }
                }
            }
        }
        figcaption{
            @include font-body-tiny ();
            padding-top: 12px;
        }
        h3+figure{
            @include media-breakpoint-down(md) {
                margin: 56px 0px 80px 0px;
            }
        }
        p {
            padding-top: 16px;
            padding-bottom: 10px;
            a {
                color: $white;
                text-decoration: none;
                border-bottom: 1px solid $link-underline;
                &:hover {
                    color: $neongreen;
                    border-bottom: 1px solid $link-underline-hover;
                    transition: all .2s ease-in; 

                }
            }
        }
        ul {
            margin-top: 20px;
            li {
                a {
                    color: $white;
                    text-decoration: none;
                    // border-bottom: 1px solid $white;
                    border-bottom: 1px solid $link-underline;
                    &:hover {
                        color: $neongreen;
                        border-bottom: 1px solid $link-underline-hover;
                        transition: color .2s ease-in; 
    
                    }
                }
            }
        }
        .responsive-object {
            position: relative;
        }
        .responsive-object iframe,
        .responsive-object object {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
    em {
        font-style: italic;
        color: #64FF00;
    }
    .millifyrirsogn {
        text-transform: uppercase;
        color: #64FF00;
        font-weight: 500;
        margin-bottom: 12px;
        letter-spacing: 1.8px;
    }
    div.millifyrirsogn + h4 {
        margin-top: 0;
    }
}