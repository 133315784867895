.homepage-block {
    padding: 0px;
    .intro-block {
        display: flex;
        justify-content: space-between;
        min-height: 600px;
        padding-left: 60px;
        position: relative;
        margin-top: 0px;
        border-bottom: 1px solid $border-color;
        margin-bottom: 135px;
        &:hover {
            text-decoration: none;
        }
        @include media-breakpoint-down(lg) {
            padding-left: 0px;
        }
        @include media-breakpoint-down(md) {
            padding-left: 20px;
        }
        @include media-breakpoint-down(sm) {
            padding-left: 0px;
            margin-top: 20px;
            flex-direction: column;
        }
        .intro-text {
            height: fit-content;
            height: -moz-fit-content;
            width: 30%;
            margin-right: 40px;
            margin: auto;
            padding-left: 40px;
            
            @include media-breakpoint-down(md) {
                width: calc(50% - 20px);
            }
            @include media-breakpoint-down(sm) {
                padding-right: 80px;
                margin: 0;
                width: 100%;
            }
            @include media-breakpoint-down(xs) {
                padding-left: 24px;
                padding-right: 24px;
            }
            h1 {
                font-size: calc(36px + (50 - 36) * ((100vw - 1200px) / (1920 - 1200)));
                @media(min-width: 1920px) {
                    font-size: 50px;
                }
                @media(max-width: 1200px){
                    font-size: 40px;
                }
                @include media-breakpoint-down(sm) {
                    line-height: 4.4rem;
                }
            }
            p {
                padding: 0px 0px 40px;
                @include media-breakpoint-down(sm) {
                    padding-bottom: 20px !important;
                    font-size: 16px !important;
                    line-height: 23px !important;
                }
            }
            .intro-button{
                width: 223px;
                height: 50px;
                border-radius: 4px;
                background-color: $neongreen;
                border: none;
                position: relative;
                &:after {
                    content: "";
                    position: absolute;
                    width: 0%;
                    height: 100%;
                    border-radius: 4px;
                    background-color: #5ce600;
                    transition: width .3s;
                    left: 0px;
                    top: 0px;
                }
                a {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                }
                span {
                    color: $black;
                    font-weight: 500;
                    position: absolute;
                    z-index: 5;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 100%;
                    text-align: center;
                    @include media-breakpoint-down(sm) {
                        font-size: 1.6rem;
                    }
                }
                @include media-breakpoint-down(sm) {
                    margin-top: 8px;
                }
                &:hover {
                    cursor: pointer;
                    &:after{
                        width: 100%;
                        transition: width .3s;
                    }
                }
                &.transition {
                    transform: translateX(0%);
                    transition: transform .3s .2s;
                }
            }
        }
        .intro-img {
            height: 75vw;
            max-height: 75vh;
            width: calc(70% - 42px);
            overflow: hidden;
            position: relative;
            @include media-breakpoint-down(xl) {
                min-height: 75vh;
            }
            @include media-breakpoint-only(lg) {
                margin-left: auto;
                max-width: 63vw;
            }
            @include media-breakpoint-down(md) {
                width: calc(50% - 20px);
            }
            @include media-breakpoint-down(sm) {
                height: auto;
                margin-top: 40px;
                max-width: 100vw;
                min-height: unset;
                width: 100%;
            }
            video {
                height: 100%;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }
    }
    .html-block {
        .html-block-content {
            margin-top: 70px;
            margin-bottom:100px;
        }
    }
}

.rich-text {
    p {
        &.employee-link {
            margin-top: 40px;
        }
        a {
                color: $white;
                text-decoration: none;
                border-bottom: 1px solid $link-underline;
                &:hover {
                    text-decoration: none;
                    color: $neongreen;
                    transition: color .2s ease-in;
                    border-bottom: 1px solid $link-underline-hover;
                }
            &.employee-link {
                text-decoration: none;
                color: $font-color-primary;
                // border-bottom: solid 3px rgba(60, 157, 211, 0.5);
                padding-bottom: 4px;
            }
        }
    }
}