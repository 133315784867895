.related-pages-container {
    background-color: $brandgreen-d1;
    margin: 80px -13px 40px 60px;
    padding: 40px 0px 120px 180px;
    position: relative;
    @include media-breakpoint-down(sm) {
        margin: 0 !important;
        padding: 40px 0px 120px 29px !important;
        width: 100vw;
    }
    .related-pages-heading {
        text-transform: uppercase;
        font-size: 1.3rem;
        font-weight: 500;
        color: $neongreen;
        @include media-breakpoint-down(sm) {
            font-size: 1.2rem;
        }
    }
    .area {
        .prev-arrow {
            display: none!important;
        }
        .next-arrow {
            background-image: url(../img/arrow.svg);
            background-repeat: no-repeat;
            background-position: 40px center;
            border: none;
            height: 272px;
            width: 33vw;
            outline: none;
            position: absolute;
            right: 0px;
            left: calc(33vw * 2);
            top: 110px;
            background-color: transparent;
            @include media-breakpoint-down(md) {
                height: 212px;
                width: 50vw;
                // left: 50vw;
            }
            @include media-breakpoint-down(sm) {
                height: 172px;
                width: 40vw;
                // left: 60vw;
            }
        }
        .slick-slide {
            width: 33vw;
            height: 380px;
            @include media-breakpoint-down(md) {
                width: 50vw;
                height: 320px;
            }
            @include media-breakpoint-down(sm) {
                height: 280px;
                width: 60vw;
            }
            &.next-up {
                display: block;
               .related-page-title {
                   opacity: 0;
               }
               .related-page-img {
                   opacity: .2;
               }
            }
            &.slick-active:not(.slick-current) {
                .related-page-title {
                    opacity: 1;
                    transition: opacity .3s linear .5s;
                }
                .related-page-img {
                    opacity: 1;
                    transition: opacity .3s linear .5s;
                }
            }
            .carousel-item {
                padding: 20px 40px 20px 0px;
                @include media-breakpoint-down(md) {
                    padding: 0px 40px 20px 0px;
                }
                @include media-breakpoint-down(xs) {
                    padding: 0px 20px 20px 0px;
                }
                a {
                    .related-page-title {
                        text-decoration: none;
                        color: $font-color-primary;
                        font-size: 3rem;
                        line-height: 3.2rem;
                        padding: 24px 0px;
                    }
                    .img-container {
                        overflow: hidden;
                    }
                    .related-page-img {
                        min-width: 100%;
                        min-height: 100%;
                    }
                    &:hover {
                        text-decoration: none!important;
                    }
                }
            }
        }
    }
    @include media-breakpoint-down(sm) {
        margin: 0 !important;
        padding: 40px 0px 120px 29px !important;
        width: 100vw;

    }
}