html {
    scroll-behavior: smooth;
}

    .nav-down + .body-section {
        .research-project-menu{
            top: 97px;
        }
    }


.research-project-menu {
    position: sticky;
    transition: all 0.3s;
    top: 0;
    background-color: $background-color;
    white-space: nowrap;
    margin-bottom: 60px;
    z-index: 35;
    padding: 20px 0 20px;

    &.list-group {
        flex-direction: unset;
    }

    .project-menu-item {
        scroll-behavior: smooth;
        text-transform: uppercase;
        margin-right: 40px;
        letter-spacing: 1.64px;
        font-weight: 500;
        text-decoration: none;
        color: $white;

        &.active {
            position: relative;
            &::before{
                content: '';
                position: absolute;
                width: 100%;
                height: 3px;
                background-color: $neongreen;
                bottom: -15px;
                left: 0;
            }
        }
        &.list-group-item{
            background-color: unset;
            display: unset;
            padding: unset;
            margin-bottom: unset;
            border: unset;
        }

        &:last-child {
            margin-right: 0;
        }
    }
}

@include media-breakpoint-down(lg) {
    .research-project-menu {
        .project-menu-item{
            margin-right: 27px;
            font-size: 16px;

            &:last-child {
                margin-right: 0;
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .research-project-menu {
    overflow: scroll;

        .project-menu-item {
            margin-right: 22px;
            font-size: 16px;

            &:last-child {
                margin-right: 0;
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    .research-project-menu {
        .project-menu-item {
            margin-right: 20px;
            font-size: 14px;
        }
    }
}