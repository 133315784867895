.info-card-block {
    margin-bottom: 80px;
    margin-top: 70px;
    max-width: 1400px;

    .info-card {
        border: 1px solid $border-color;
        padding: 40px 30px;
        height: calc(100% - 40px);
        margin-bottom: 40px;
        transition: all .3s linear;

        &:hover {
            background-color: rgba(255, 255, 255, 0.08);

            a {
                .card-title {
                    color: $neongreen;
                }
            }
        }

        .card-title {
            font-size: 20px;
            font-weight: 700;
            margin-bottom: 20px;
        }

        a {
            text-decoration: none;
            color: $white;
        }
    }

}