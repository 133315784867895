
.fiftyskills-block {
    padding: 0px;
    .jobs-container {
        margin-bottom: 66px;
        .logo {
            margin-bottom: 18px;
            img {
                height: 67px;
            }
            @include media-breakpoint-down(sm) {
                margin-left: 28px;
            }
        }
        .or-logo {
            img {
                height: 32px;
            }
        }
        .on-logo {
            img {
                height: 50px;
            }
        }
        .text-container {
            background-color: $white-08;
            padding: 16px 28px;
            margin-bottom: 16px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            &:hover {
                background-color: $white-16;
            }
            @include media-breakpoint-down(sm) {
                flex-direction: column;
                align-items: flex-start;
            }
            a {
                color: $neongreen;
                text-decoration: none;
                font-weight: 500;
                font-size: 14px;
                line-height: 14px;
                white-space: nowrap;
                padding-left: 7px;
                @include media-breakpoint-down(sm) {
                    margin-top: 20px;
                }
            }
            .text {
                .title {
                    font-weight: 500;
                    font-size: 20px;
                    padding-bottom: 5px;
                }
                .subtitle {
                    font-size: 15px;
                    line-height: 15px;
                }
            }
        }
    }
}