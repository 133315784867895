.timeline-page {
    width: 1228px;
    margin: 0px auto;
    position: relative;
    // overflow-x: hidden;
    @include media-breakpoint-down(xl) {
        width: 100%;
        padding: 0px 60px;
    }
    @include media-breakpoint-down(lg) {
        padding: 0px 40px;
    }
    @include media-breakpoint-down(sm) {
        padding: 0px;
    }
    .year-block {
        position: relative;
        width: 100%;
        height: 240px;
        @include media-breakpoint-down(sm) {
            height: 120px;
        }
        &:nth-of-type(2){
            height: 80px;
            &:before {
                display: none;
            }
            &:after {
                display: none;
            }
            .year-container{
                left: 25%!important;
                top: 0px;
                transform: translateX(-50%);
                .year {
                    left: calc(25% + 68px)!important;
                    top: 50%;
                    transform: translateY(-50%)!important;
                    bottom: unset!important;
                }
            }
            @include media-breakpoint-down(sm) {
                height: 120px;
                &:before {
                    display: block;
                }
                .year-container{
                    left: 30%!important;
                    top: 50%;
                    transform: translate(0%, -50%);
                    .year {
                        bottom: -32px!important;
                        left: 50%!important;
                        transform: translateX(-50%)!important;
                        top: unset!important;
                    }
                }
            }
        }
        &.even {
            &:before {
                content: "";
                width: 40%;
                height: 50%;
                position: absolute;
                left: 25%;
                border-radius: 0px 0px 0px 24px;
                border: 1px $border-color;
                border-style: hidden hidden solid solid;
            }
            &:after {
                content: "";
                width: 10%;
                height: 50%;
                position: absolute;
                right: 25%;
                top: 50%;
                border-radius: 0px 24px 0px 0px;
                border: 1px $border-color;
                border-style: solid solid hidden hidden;
                z-index: -1;
            }
            .year-container{
                left: unset;
                right: 30%;
                .year {
                    top: -24px;
                    left: 50%;
                    transform: translateX(-50%);
                    @include media-breakpoint-down(sm) {
                        top: unset;
                        bottom: -32px;
                    }
                }
            }
            @include media-breakpoint-down(sm) {
                &:before {
                    width: 56%;
                    left: 12%;
                }
                &:after {
                    width: 20%;
                    right: 12%;
                }
            }
        }
        &.odd {
            &:before {
                content: "";
                width: 10%;
                height: 50%;
                position: absolute;
                left: 25%;
                top: 50%;
                border-radius: 24px 0px 0px 0px;
                border: 1px $border-color;
                border-style: solid hidden hidden solid;
            }
            &:after {
                content: "";
                width: 40%;
                height: 50%;
                position: absolute;
                right: 25%;
                border-radius: 0px 0px 24px 0px;
                border: 1px $border-color;
                border-style: hidden solid solid hidden;
                z-index: -1;
            }
            .year-container{
                left: 30%;
                .year {
                    bottom: -32px;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
            @include media-breakpoint-down(sm) {
                &:after {
                    width: 56%;
                    right: 12%;
                }
                &:before {
                    width: 20%;
                    left: 12%;
                }
            }
        }
        .year-container {
            position: absolute;
            top: 50%;
            left: 25%;
            transform: translateY(-50%);
            // background-color: $white;
            .year {
                position: absolute;
                font-size: 2.4rem;
                line-height: 2.9rem;
                color: $font-color-primary;
                font-weight: bold;
                margin: 0px;
                width: fit-content;
                width: -moz-fit-content;
            }
            .logo {
                // width: 80px;
                // height: 80px;
                padding: 16px;
                background-color: transparent;
                position: relative;
            }
            // .ring-container {
            //     width: 80px;
            //     height: 80px;
            //     padding: 16px;
            //     background-color: $white;
            //     position: relative;
            //     .outer-ring {
            //         position: absolute;
            //         border: solid 10px $grey-d1;
            //         width: 48px;
            //         height: 48px;
            //         padding: 4px;
            //         border-radius: 50%;
            //         .inner-ring {
            //             position: absolute;
            //             width: calc(100% - 8px);
            //             height: calc(100% - 8px);
            //             border-radius: 50%;
            //             background-color: $blue;
            //         }
            //     }
            // }
        }
    }
    .event-container {
        display: flex;
        flex-direction: row;
        min-width: 100%;
        position: relative;
        @include media-breakpoint-down(sm) {
            padding: 40px 0px;
        }
        &:nth-of-type(3) {
            .event-text {
                transition: none!important;
            }
            .event-img {
                transition: none!important;
            }
        }
        &:last-of-type {
            margin-bottom: 200px;
            &:after {
                content: "";
                position: absolute;
                top: 100%;
                height: 120px;
                width: 30%;
                z-index: -2;
                border: 1px $border-color;
                @include media-breakpoint-down(sm) {
                    height: 40px;
                }
            }
            &.even {
                &:after {
                    right: 25%;
                    border-radius: 0px 0px 24px 0px;
                    border-style: hidden solid solid hidden;
                    @include media-breakpoint-down(sm) {
                        right: 12%;
                    }
                }
            }
            &.odd {
                &:after {
                    left: 25%;
                    border-radius: 0px 0px 0px 24px;
                    border-style: hidden hidden solid solid;
                    @include media-breakpoint-down(sm) {
                        left: 12%;
                    }
                }
            }
            
        }
        &:before {
            content: "";
            position: absolute;
            height: 100%;
            width: calc(100% - 25%);
            z-index: -2;
            @include media-breakpoint-down(sm) {
                width: calc(100% - 12%);
            }
        }
        &.has-img {
            padding: 0px 0px 40px;
        }
        .event-text {
            width: 100%;
            height: fit-content;
            height: -moz-fit-content;
            background-color: $brandgreen-d1;
            padding: 56px 40px 40px;
            box-shadow: 0 10px 40px 0 rgba(0,0,0,0.1);
            align-self: center;
            h3 {
                font-weight: 400;
                font-size: 3rem;
                line-height: 3.2rem;
            }
        }
        .event-img {
            z-index: -1;
            max-width: 700px;
            overflow: hidden;
            position: absolute;
            height: 100%;
            @include media-breakpoint-down(xl) {
                max-width: 100%;
            }
            img {
                min-height: 100%;
                min-width: 100%;
            }
        }
        &.odd {
            &:before {
                left: 25%;
                border-left: solid 1px $border-color;
                @include media-breakpoint-down(sm) {
                    left: 12%;
                }
            }
            &:not(.has-img) {
                .event-text {
                    transition: margin .3s ease-in-out .2s;
                }
            }
            &.has-img {
                .event-text {
                    margin-top: 40px;
                }   
            }
            &.visible {
                .event-text{
                    margin-left: 0px;
                }
                .event-img{
                    opacity: 1;
                }
            }
            .event-text {
                margin-left: -80px;
                transition: margin .3s ease-in-out .3s;
                width: 60%;
                @include media-breakpoint-down(sm) {
                    margin-top: 0px;
                }
            }
            .event-img {
                opacity: .1;
                transition: opacity .6s ease-in-out 0s;
                right: 0;
                @include media-breakpoint-down(sm) {
                    height: 100%;
                    max-height: 300px;
                }
            }
        }
        &.even {
            &:before {
                right: 25%;
                border-right: solid 1px $border-color;
                @include media-breakpoint-down(sm) {
                    right: 12%;
                }
            }
            &:not(.has-img) {
                .event-text {
                    transition: margin .3s ease-in-out .3s, transform .3s ease-in-out .3s;
                }
            }
            &.has-img {
                .event-text {
                    margin-top: 40px;
                }   
            }
            &.visible {
                .event-text{
                    transform: translateX(0px);
                }
                .event-img{
                    opacity: 1;
                }
            }
            .event-text {
                margin-left: auto;
                transition: margin .3s ease-in-out .3s, transform .3s ease-in-out .3s;
                width: 60%;
                transform: translateX(80px);
                @include media-breakpoint-down(sm) {
                    margin-top: 0px;
                }
            }
            .event-img {
                opacity: .1;
                transition: opacity .6s ease-in-out 0s;
                @include media-breakpoint-down(sm) {
                    height: 100%;
                    max-height: 300px;
                }
            }
        }
    }
}