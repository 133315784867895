.blog-page-container {
    display: flex;
    // margin-top: 100px;
    max-width: 1140px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    @include media-breakpoint-down(md) {
        margin-top: 50px;
    }
    @include media-breakpoint-down(sm) {
        margin-top: 30px;
    }
    &>.row {
        margin: 0 auto;
    }
    .blog-page {
        @include media-breakpoint-down(md) {
            max-width: calc(100% - 80px);
            margin: 0 auto;
            padding: 0px;
        }
        @include media-breakpoint-down(sm) {
            max-width: calc(100% - 40px);
        }
        &.even{
            .blog-page-text{
                padding: 80px 100px 40px 60px;
                @include media-breakpoint-down(md) {
                    padding: 60px;
                }
                @include media-breakpoint-down(sm) {
                    padding: 30px;
                }
                @include media-breakpoint-down(xs) {
                    padding: 20px;
                }
                &:before{
                    left: auto;
                    right: -200px;
                }
                .blog-page-img {
                    left: unset;
                    right: 60px;
                    transform: translateX(100%);
                    @include media-breakpoint-down(lg) {
                        right: 40px;
                    }
                    @include media-breakpoint-down(md) {
                        right: unset;
                        left: 0px;
                        transform: translateX(0px);
                    }
                    img {
                        right: unset;
                        left: 0px;

                    }
                }
            }
        }
        &.odd {
            .blog-page-text {
                .blog-page-img {
                    figure {
                        margin-left: auto;
                        @include media-breakpoint-down(md) {
                            margin-left: 0px;
                        }
                    }
                }
            } 
        }
    
        .blog-page-text {
            margin: 80px 0px;
            position: relative;
            // background-color: $white;
            border: 1px solid $border-color-secondary;
            box-shadow: 0 10px 80px 0 rgba(0,0,0,0.1);
            padding: 80px 60px 40px 120px;
            @include media-breakpoint-down(md) {
                padding: 60px;
                margin-bottom: 0px;
            }
            @include media-breakpoint-down(sm) {
                padding: 30px;
            }
            @include media-breakpoint-down(xs) {
                padding: 20px;
            }
            &:before{
                content: "";
                position: absolute;
                top: -200px;
                left: -200px;
                height: 600px;
                width: 600px;
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                z-index: -1;
                @include media-breakpoint-down(xs) {
                    height: 500px;
                    width: 500px;
                }
            }
            // &.blue {
            //     &:before {
            //         background-image: url(../img/oval-blue.svg);
            //     }
            // }
            // &.green {
            //     &:before {
            //         background-image: url(../img/oval-yellow.svg);
            //     }
            // }
            // &.pink {
            //     &:before {
            //         background-image: url(../img/oval-pink.svg);
            //     }
            // }
            .blog-page-img {
                position: absolute;
                top: 80px;
                left: 60px;
                width: calc(((100vw - 1140px) / 2) + (1140px / 3));
                height: 420px;
                transform: translateX(-100%);
                @include media-breakpoint-down(lg) {
                    width: calc(((100vw / 12) * 5) + 40px);
                    left: 40px;
                }
                @include media-breakpoint-down(md) {
                    position: unset;
                    transform: translateX(0px);
                    width: 100%;
                    left: 0px;
                    height: auto;
                    margin-bottom: 40px;
                }
                figure {
                    display: table;
                    flex-direction: column;
                    width: fit-content;
                    width: -moz-fit-content;
                    img {
                        max-width: 100%;
                        height: auto;
                        max-height: 100%;
                        position: relative;
                        z-index: 2;
                    }
                    figcaption{
                        @include font-body-tiny ();
                        color: $font-color-primary;
                        padding-top: 12px;
                    }
                }
                video{
                    width: 100%;
                }
            }
            .date {
                text-transform: uppercase;
                color: $neongreen;
                font-weight: 500;
                font-size: 1.5rem;
                line-height: 2.6rem;
            }
            h1 {
                font-size: 3rem;
                line-height: 3.2rem;
                color: $font-color-primary;
                font-weight: 400;
                max-width: 480px;
                padding: 8px 0px 16px;
                @include media-breakpoint-down(sm) {
                    font-size: 2.4rem;
                    line-height: 3rem;
                }
                @include media-breakpoint-down(xs) {
                    font-size: 2.2rem;
                }
            }
            .rich-text {
                max-width: 650px;
                // @include media-breakpoint-down(sm) {
                //     display: none;
                // }
                p {
                    margin-bottom: 24px
                }
                figure {
                    width: 100%!important;
                    margin: 20px 0px;
                    .img-container {
                        height: auto!important;
                        img {
                            width: 100%;
                            height: auto;
                        }
                    }
                    figcaption {
                        margin-top: 10px;
                        font-size: 1.3rem;
                        line-height: 1.8rem;
                        font-weight: 400;
                    }
                }
            }
            a {
                color: $blue;
                font-size: 1.8rem;
                font-weight: 400;
                line-height: 2.6rem;
                text-decoration: none;
                &:hover{
                    text-decoration: underline;
                }
            }
            .share-container {
                width: 40%;
                display: flex;
                flex-direction: row;
                p.share {
                    width: fit-content;
                    width: -moz-fit-content;
                    text-transform: uppercase;
                    color: $neongreen;
                    font-weight: 500;
                    font-size: 1.5rem;
                    line-height: 2.6rem;
                    margin-right: 40px;
                    @include media-breakpoint-down(sm) {
                        font-size: 1.6rem;
                        line-height: 2.6rem;
                    }
                }
                a {
                    svg{
                        height: 16px;
                    }
                    padding: 0px 8px;
                    &.facebook, &.twitter{
                        svg {
                            height: 100%;
                            &:hover {
                                circle{
                                    fill: $neongreen;
                                    transition: fill .3s ease-in;
                                }
                            }
                        }
                    }
            
                    &.mail {
                        &:hover {
                            path {
                                fill: $neongreen;
                                transition: fill .3s ease-in;
                            }
                        }
                    } 
                }
            }
        }
    }
}
