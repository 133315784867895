.ch2{
    .ch2-btn:not(.ch2-open-settings-btn){
        background-color: $grey !important;

        &:hover{
            background: $grey;
            box-shadow: 0px 0px 20px 0 rgba(73, 70, 67, .66) !important;
            border: none;
        }
    }
}