footer{
    margin-top: 170px;
    .footer.md-hide {
        display: flex;
        flex-direction: column;
        max-width: calc(100% - 208px);
        margin: 0 auto;
        @include media-breakpoint-up(xxl) {
            max-width: 1240px;
        }
        @include media-breakpoint-down(lg) {
            max-width: calc(100% - 160px);
        }
        @include media-breakpoint-down(md) {
            display: none;
        }
        .nav-link {
            padding: 0px 16px;

        }
        .topnav {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .icon {
                padding-left: 22px;
                display: flex;
                flex-direction: row;
                .nav-item:nth-child(1), .nav-item:nth-child(2), .nav-item:nth-child(3) {
                    position: relative;
                }
                .nav-item:nth-child(4) {
                    position: relative;
                    // width: 90px;
                    svg{
                        height: 50px;
                    }
                }
                .nav-item {
                    width: 115px;

                    .nav-link{
                        position: absolute;
                        bottom: 14px;
                        right: 4px;   
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        @include media-breakpoint-down(md) {
                            bottom: 8px;
                        }
                     
                        .line {
                            width: 85px;
                            height: 1px;
                            background-color: $neongreen;
                            top: 14px;
                            position: relative;
                        }
                    }
                }
            }
            .logo{
                padding-bottom: 12px;
                // border-bottom: 1px solid $border-color;
                width: 100%;
                @include media-breakpoint-down(md) {
                    padding-bottom: 8px;
                }
                a {
                    svg{
                        vertical-align: sub;
                    }
                    .en-logo {
                        width: 362px;
                    }
                    span{
                        color: $font-color-primary;
                        padding-left: 5px;
                    }
                }
            }
        }
        .subnav{
            padding: 40px 40px 0px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 70px;
            font-size: 1.5rem;
            font-weight: 400;
            .contact {
                display: flex;
                flex-direction: row;
                margin-right: -37px;
                @include media-breakpoint-down(lg) {
                    flex-direction: column;
                }
                .nav-item {
                    @include media-breakpoint-down(lg) {
                        margin-bottom: 8px;
                    }
                    .nav-link {
                        @include media-breakpoint-up(md){
                            &:hover {
                                color: $neongreen;
                                transition: color .2s ease-in; 
                            }
                        }
                        svg {
                            margin-right: 8px;
                            @include media-breakpoint-down(lg) {
                                margin-right: 24px;
                            }
                        }
                    }
                }
                .nav-item:nth-of-type(3){
                    a.nav-link {
                        svg {
                            fill: transparent;
                        }
                        padding: 0px 0px 0px 16px;
                    }
                }
            }
            .about {
                display: flex;
                flex-direction: column;
                .adress-container {
                    margin-bottom: 32px;
                }
                .adress-container, .hours-container {
                    display: flex;
                    flex-direction: row;
                    svg {
                        height: 32px;
                        width: 21px;
                        padding-top: 8px;
                        fill: $white;
                        margin-right: 20px;
                    }
                    .adress {
                        display: flex;
                        flex-direction: column;
                        .kt {
                            padding-right: 10px;
                            margin-right: 10px;
                            border-right: solid 1px $font-color-primary;
                        }
                    }
                    .hours {
                        display: flex;
                        flex-direction: column;
                    }
                }
                .adress-container{
                    width: fit-content;
                    width: -moz-fit-content;
                    @include media-breakpoint-up(md){
                        &:hover {
                            cursor: pointer;
                            .adress {
                                div:nth-of-type(1) {
                                    color: $neongreen;
                                    transition: color .2s ease-in; 
                                }
                            }
                        }
                    }
                    svg {
                        width: 24px;
                        padding-top: 6px;
                    }
                }
                .personuvernd-container{
                    margin-left: 25px;
                    margin-top: 20px;
                    a{
                        color: $white;
                        border-bottom: 1px solid $link-underline;
                        padding: 0;
                        margin-left: 16px;
                        white-space: nowrap;
                        &:hover{
                            transition: all .2s ease-in;
                            border-bottom: 1px solid $link-underline-hover;
                        } 
                        // @include media-breakpoint-down(sm) {
                        //     border-bottom: unset !important;
                        //     text-decoration: underline $link-underline !important;
                        // }
                   }
                }
            }
            .nav-link{
                color: $font-color-primary;
            }
        }
    }
    .footer.md-show {
        display: none;
        @include media-breakpoint-down(md) {
            display: block;
            padding: 0px;
            .topnav {
                .logo {
                    margin: 24px 0px 24px 60px;
                    padding: 8px 16px;
                    @include media-breakpoint-down(sm) {
                        margin: 24px 0px 24px 40px;
                    }
                    @include media-breakpoint-down(xs) {
                        padding: 8px 0px;
                        margin: 24px 0px 24px 24px;
                    }
                    a {
                        position: relative;
                        span {
                            font-weight: 600;
                            font-size: 1.5rem;
                            position: absolute;
                            bottom: 0px;
                            padding: 0px 0px 0px 16px;
                            color: $font-color-primary;
                            @include media-breakpoint-down(sm) {
                                font-size: 1.4rem;
                            }
                        }

                    }
                }
            }
            .middlenav {
                padding: 20px 104px;
                display: flex;
                flex-direction: row;
                font-weight: 400;
                justify-content: space-between;
                @include media-breakpoint-down(md) {
                    font-size: 1.5rem;
                }
                @include media-breakpoint-down(sm) {
                    flex-direction: column;
                    width: 80%;
                    // width: fit-content;
                    width: -moz-fit-content;
                    margin: 0 auto;
                    padding: 20px 48px;
                }
                .about {
                    display: flex;
                    flex-direction: column;
                    .adress-container, .hours-container {
                        display: flex;
                        flex-direction: row;
                        svg {
                            width: 21px;
                            height: 30px;
                            padding-top: 8px;
                            fill: $white;
                            margin-right: 20px;
                        }
                        .adress {
                            display: flex;
                            flex-direction: column;
                            .kt {
                                padding-right: 10px;
                                margin-right: 10px;
                                border-right: solid 1px $font-color-primary;
                            }
                            div:nth-child(2) {
                                @include media-breakpoint-down(md) {
                                    font-size: 1.4rem;
                                }
                            }
                        }
                        .hours {
                            display: flex;
                            flex-direction: column;
                            a:nth-child(2) {
                                @include media-breakpoint-down(md) {
                                    font-size: 1.4rem;
                                }
                            }
                        }
                    }
                    .adress-container{
                        width: auto;
                        margin-bottom: 32px;
                        @include media-breakpoint-up(sm){
                            &:hover {
                                cursor: pointer;
                                .adress {
                                    div:nth-of-type(1) {
                                        color: $neongreen;
                                        transition: color .2s ease-in; 
                                    }
                                }
                            }
                        }
                        svg {
                            width: 24px;
                            padding-top: 6px;
                            height: 30px;
                        }
                    }
                    .personuvernd-container{
                        margin-left: 40px;
                        margin-top: 20px;
                        a{
                            color: $white;
                            border-bottom: 1px solid $link-underline;
                            padding: 0;
                            width: fit-content;
                            @include media-breakpoint-down(md) {
                                margin-bottom: 45px;
                            }
                            @include media-breakpoint-down(sm) {
                                border-bottom: unset !important;
                                text-decoration: underline $link-underline-hover !important;
                            }
                        }
                    }
                }
                .contact {
                    display: flex;
                    flex-direction: column;
                    @include media-breakpoint-down(sm) {
                        margin-top: 32px;
                    }
                    .nav-item {
                        margin-right: 20px;
                        margin-bottom: 8px;
                        a.nav-link {
                            padding: 0px;
                            color: $font-color-primary;
                            @include media-breakpoint-up(sm){
                                &:hover {
                                    color: $neongreen;
                                    transition: color .2s ease-in; 
                                }
                            }
                            @include media-breakpoint-down(sm) {
                                border: 1px solid $border-color;
                                padding: 13px 12px;
                                margin-bottom: 20px;
                            }
                            svg {
                                margin-right: 20px;
                            }
                        }
                    }
                    .nav-item:nth-of-type(3){
                        a.nav-link {
                            svg {
                                fill: transparent;
                            }
                        }
                    }
                }
            }
            .iconnav {
                display: flex;
                .icon {
                    height: 76px;
                    width: 100%;
                    justify-content: center;
                    display: flex;
                    flex-direction: row;
                    margin: 24px 60px;
                    @include media-breakpoint-down(sm) {
                        margin: 24px 40px 24px 0px;
                    }
                    @include media-breakpoint-down(xs) {
                        margin: 24px 24px 24px 0px;
                    }

                    .nav-item {
                        width: 114px;
                        .nav-link{
                                justify-content: center !important;
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                             
                                .line {
                                    width: 85px;
                                    height: 1px;
                                    background-color: $neongreen;
                                    top: 14px;
                                    position: relative;
                                }
                        }
                        &:nth-child(1) {
                            top: -4px;
                            position: relative;
                        }
                        // &:nth-child(1), &:nth-child(2), &:nth-child(3) {
                        //     position: relative;
                        //     // &:after {
                        //     //     content: "";
                        //     //     position: absolute;
                        //     //     right: 0px;
                        //     //     bottom: 0px;
                        //     //     height: 30%; 
                        //     //     width: 100%;
                        //     //     border-right: 1px solid $font-color-primary;
                        //     // }
                        // }
                    }
                    .nav-item:nth-child(4) {
                        position: relative;
                        // width: 90px;
                        svg{
                            height: 44px;
                        }
                    }
                }
            }
        }
    }
}