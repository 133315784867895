.subsidiary_block {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 40px 100px 60px;

    @include media-breakpoint-up(xxl) {
        max-width: 1440px;
        margin: 0 auto;
    }

    @include media-breakpoint-down(lg) {
        padding: 40px 60px 60px;
    }

    @include media-breakpoint-down(md) {
        flex-direction: column;
        padding: 40px 0px 60px;
        max-width: 380px;
        margin: 0 auto;
        width: calc(100% - 80px);
    }

    @include media-breakpoint-down(xs) {
        width: calc(100% - 48px);
    }

    div.block-container{
        width: calc(20% + 20px);
        box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
        position: relative;
        padding: 24px 0px;
        height: 300px;
        border: 1px solid $border-color;
        &:hover {
            background-color: rgba(255,255,255,0.08);
            transition: background-color .3s linear;
            img {
                filter: none !important;
            }
        }

        @include media-breakpoint-down(md) {
            width: 100%;
            margin-bottom: 20px;
        }

        a.img-container{
            height: 96px;
            position: relative;
            display: flex;
            width: 100%;
            justify-items: center;

            img {
                max-height: 100%;
                max-width: 100%;
                position: absolute;
                left: 50%;
                bottom: 0;
                transform: translateX(-50%);
                filter: saturate(0) brightness(10);
                //transition: all 0.5s ease-in-out;

            }
        }

        div.text-container {
            margin: 0 auto;
            display: table;
            flex-direction: column;
            padding: 32px 0px 0px;
            max-width: 80%;

            div {
                display: flex;
                flex-direction: row;

                img {
                    max-height: 30px;
                    width: 18px;
                    align-self: center;
                    margin-right: 13px;

                }

                p {
                    margin-bottom: 0px;
                    font-size: 1.6rem;

                    a {
                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
            }
        }
        &.veitur {
            // border-top: solid 10px $blue-subsidiary;
            svg {

            }
            
        }
        // &.ljosleidarinn {
        //     border-top: solid 10px $pink-ljosleidari;
        // }
        // &.on {
        //     border-top: solid 10px $orange;
        // }
        // &.carbfix{
        //     border-top: solid 10px $carbfix;
        //     a.img-container img{
        //         max-height: 80px;
        //     }
        // }
    }
}

.subsidiary_block a {
    color: $white;
    &:hover {
        text-decoration: none;
        color: $neongreen;
        transition: all .2s ease-in;
    }
}