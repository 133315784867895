.episode-block{
    padding: 0 320px;
    @include media-breakpoint-up(xxl) {
        max-width: 1440px;
        margin: 0 auto;
    }
    @include media-breakpoint-down(lg) {
        padding: 0px 80px;
    }
    @include media-breakpoint-down(md) {
        padding: 0px 60px;
    }
    @include media-breakpoint-down(sm) {
        padding: 0px 40px;
    }
    @include media-breakpoint-down(xs) {
        padding: 0px 24px;
    }
    h2 {
        padding-top: 40px;
        @include font-h2();
        margin-top: 30px;
        margin-bottom: -16px;
        @include media-breakpoint-down(sm) {
            font-size: 3.2rem;
        }
    }
    .description{
        margin-top: 16px;
        margin-bottom: 20px;
    }
    .responsive-object{
        position: relative;
        > div{
            padding-bottom: 56.25%;
            position: relative;
            width: 100%;
            iframe{
                top: 0;
                left: 0;
                position: absolute;
                height: 100%;
                width: 100%;
            }
        }
    }
}