.image-card-block {
    max-width: 1400px;
    margin-bottom: 80px;
    margin-top: 70px;
    .image-card {
        margin-bottom: 50px;
        .card-title {
            font-size: 20px;
            font-weight: 700;
        }
    }
}